import { useState } from 'react';
import useAppContext from 'hooks/useAppContext';
import useMaps from 'hooks/useMaps';
import { useWindowSize } from 'hooks/useWindowSize';
import { useMap } from 'react-leaflet';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useSignals from 'hooks/useSignals';
import { getMomentum } from 'utils/dateHelper';
import { MapSelect } from '../components/MapSelect';
import { MapSearch } from '../components/MapSearch';
import { MapSignals } from '../components/MapSignals';

type ActionsControlProps = {
  width: number;
  height: number;
};

export default function MapActionsControl() {
  const lmap = useMap();
  const { width } = useWindowSize();

  const [open, setOpen] = useState(false);

  const styles: React.CSSProperties = { width: '100%', height: '30px', color: 'black', backgroundColor: 'white' };
  const controls = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, width: 210, height: 210, padding: '10px' }}>
      <h2 style={styles}>Map actions</h2>
      <MapSelect nativeCSS={styles} />
      <MapSearch nativeCSS={{ ...styles, border: 'solid, 1px' }} />
      <MapSignals nativeCSS={styles} />
    </div>
  );

  return (
    //@ts-ignore
    lmap._isFullscreen && (
      <div className={'leaflet-top leaflet-right'}>
        <div className='leaflet-control leaflet-bar' style={{ backgroundColor: 'white' }}>
          {width && width < 500 ? (
            <IconButton onClick={() => setOpen(true)}>
              <MoreVertIcon />
            </IconButton>
          ) : (
            controls
          )}
        </div>
      </div>
    )
  );
}
