import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Checkbox, Divider, FormControlLabel, Grid2 as Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MapLayers } from 'entities';
import useMaps from 'hooks/useMaps';
import useAxios from 'hooks/useAxios';
import useConfirm from 'hooks/useConfirm';
import useNotification from 'hooks/useNotification';
import { Toggler } from 'components/shared/Toggler';
import { LatLngBounds } from 'leaflet';
import { dE } from '@fullcalendar/core/internal-common';

interface BoundProps {
  min?: number;
  max?: number;
  step: number;
  name: string;
  disabled: boolean;
}

interface Borders {
  north: number;
  east: number;
  south: number;
  west: number;
}

interface BoundInputProps extends BoundProps {
  value: number;
  onChange: (value: number, name: string) => void;
}

const BoundInput = ({ value, name, min, max, step, disabled, onChange }: BoundInputProps) => {
  // const [_value, setValue] = React.useState(0);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === '' ? 0 : Number(event.target.value), name);
  };

  return (
    <>
      <TextField
        key={name}
        size='small'
        label={`${name} border`}
        value={value}
        disabled={disabled}
        onChange={handleInputChange}
        sx={{ width: 100 }}
        inputProps={{
          step: step,
          min: min ?? -1000,
          max: max ?? 1000,
          type: 'number',
        }}
      />
    </>
  );
};

export const MapBounds = () => {
  const { get, patch } = useAxios();
  const { success, error } = useNotification();
  const { devicesBounds, layer, map, setLayer, setDevicesBounds } = useMaps();
  const { confirm, ConfirmDialog } = useConfirm();

  const [borders, setBorders] = React.useState<Borders>();

  const [loading, setLoading] = React.useState(false);
  const [fracStep, setFracStep] = React.useState(true);
  const [genType, setGenType] = React.useState('Layers');

  const onReset = () => {
    setLoading(true);
    confirm('Are you sure you want to reset to the previous bounds?').then((yes) => {
      if (yes && layer && genType === 'Layers') {
        get<MapLayers>(`/api/maplayers/${layer.id}`).then(setLayer);
      } else if (yes) setDevicesBounds(new LatLngBounds([-256, 0], [0, 256]));
      setLoading(false);
    });
  };

  const onSubmit = () => {
    if (!layer) return;
    setLoading(true);
    confirm('Are you sure you want to update these bounds?').then((yes) => {
      if (yes) {
        if (genType === 'Layers')
          patch<MapLayers>(`/api/maplayers/${layer.id}`, layer).then((ok) => {
            if (ok) success('Map bounds updated');
            else error('Failed to update map bounds');
            setLoading(false);
          });
      }
      setLoading(false);
    });
  };

  const handleChange = (value: number, name: string) => {
    const _borders = { ...borders!!, [name.toLocaleLowerCase()]: value };
    setBorders(_borders);
    if (genType === 'Layers' && layer) setLayer({ ...layer, [name.toLocaleLowerCase()]: value });
    if (genType === 'Devices' && devicesBounds) setDevicesBounds(_borders ? new LatLngBounds([_borders.south, _borders.west], [_borders.north, _borders.east]) : undefined);
  };

  const init = () => {
    if (genType === 'Devices' && devicesBounds)
      setBorders({ north: devicesBounds.getNorth(), east: devicesBounds.getEast(), south: devicesBounds.getSouth(), west: devicesBounds.getWest() });
    if (genType === 'Layers' && layer) setBorders(layer as unknown as Borders);
  };

  React.useEffect(() => {
    init();
  }, [genType, devicesBounds]);

  React.useEffect(() => {
    init();
  }, []);

  return (
    borders && (
      <Box display={'flex'} gap={2} flexDirection={'column'} sx={{ maxWidth: 300 }}>
        <ConfirmDialog />
        <Box display={'flex'} gap={1} flexDirection={'column'}>
          <Box display={'flex'} justifyContent={'center'} flexGrow={1}>
            <BoundInput value={borders.north} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'North'} key={'North'} disabled={!layer} />
          </Box>
          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <BoundInput value={borders.east} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'East'} key={'East'} disabled={!layer} />
            <BoundInput value={borders.west} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'West'} key={'West'} disabled={!layer} />
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <BoundInput value={borders.south} onChange={handleChange} step={fracStep ? 0.1 : 1} name={'South'} key={'South'} disabled={!layer} />
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} gap={1}>
          <Toggler value={genType} values={[{ name: 'Devices', disabled: map && map?.id > 10 }, { name: 'Layers' }]} onChange={setGenType} />
          <FormControlLabel control={<Checkbox size='small' checked={fracStep} onChange={(_, c) => setFracStep(c)} />} label={'Fractional steps'} sx={{ mr: 0 }} />
        </Box>
        <Box component='div' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 2 }}>
          <Button type='button' variant='outlined' onClick={onReset}>
            Reset
          </Button>
          <LoadingButton loading={loading} variant='contained' onClick={onSubmit}>
            Save
          </LoadingButton>
        </Box>
      </Box>
    )
  );
};
