import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { ContactGroup } from 'entities';

export default function ContactGroupsTable() {
  const columns: GridColDef<ContactGroup>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/contactGroups');
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid dataUrl='/api/contactGroup/query' orderBy={'name asc'} columnDefinitions={columns} />;
}
