import { useContext } from 'react';
import { InspectionContext } from 'contexts/InspectionContext';

const useInspection = () => {
  const context = useContext(InspectionContext);
  if (!context) throw new Error('context must be use inside provider');
  return context;
};

export default useInspection;
