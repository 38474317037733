import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { Box, Grid2 as Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';

// project imports
import MainCard from 'components/cards/MainCard';
import Logo from 'components/shared/Logo';
import AuthFooter from 'components/cards/AuthFooter';

// types
import { ThemeMode } from 'types/theme';

type AuthPageProps = {
  children: ReactNode;
};

const AuthWrapperDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === ThemeMode.DARK ? theme.palette.background.default : theme.palette.grey[100],
  minHeight: '100vh',
}));

export const AuthWrapper = ({ children }: AuthPageProps) => {
  const theme = useTheme();

  return (
    <AuthWrapperDiv>
      <Grid container direction='column' justifyContent='flex-end' sx={{ minHeight: '100vh' }}>
        <Grid size={{ xs: 12 }}>
          <Grid container justifyContent='center' alignItems='center' sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
              <MainCard
                sx={{
                  maxWidth: { xs: 400, lg: 475 },
                  margin: { xs: 2.5, md: 3 },
                  '& > *': {
                    flexGrow: 1,
                    flexBasis: '50%',
                  },
                }}
                content={false}
              >
                <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                  <Grid container spacing={2} alignItems='center' justifyContent='center'>
                    <Grid sx={{ mb: 3 }}>
                      <Typography component={Link} to='/' sx={{ textDecoration: 'none', color: theme.palette.secondary.main }}>
                        <Logo />
                      </Typography>
                    </Grid>
                    {children}
                  </Grid>
                </Box>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12 }} sx={{ p: 2 }}>
          <AuthFooter />
        </Grid>
      </Grid>
    </AuthWrapperDiv>
  );
};
