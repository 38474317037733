import { Button, Grid2 as Grid, Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, FormProvider, UseFormReturn } from 'react-hook-form';
import { FieldAttributes, Field } from '../components/fields';
import { LoadingButton } from '@mui/lab';
import logger from 'utils/logger';
import { NamedEntity } from 'entities/base/Entity';

export interface BaseFormProps {
  loading?: boolean;
  readOnly?: boolean;
  methods: UseFormReturn<any, any, undefined>;
  formSchema: FieldAttributes[];
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<any>;
}

export const BaseForm = ({ methods, formSchema, readOnly, loading, onCancel, onSubmit }: BaseFormProps) => {
  return (
    <FormProvider {...methods}>
      <form className='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {formSchema.map((field, key) => (
            <Grid key={key} size={{ xs: 12, sm: field.fullWidth ? 12 : (field.span ?? 6), md: field.fullWidth ? 12 : (field.span ?? 6) }}>
              <Field disabled={readOnly} {...field} />
            </Grid>
          ))}
        </Grid>
        {readOnly && (
          <Box component='div' sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, mt: 2 }}>
            <Button type='button' variant='contained'>
              Go back
            </Button>
          </Box>
        )}
        {!readOnly && (
          <Box component='div' sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2, mt: 2 }}>
            <Button type='button' variant='outlined' onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton loading={loading} type='submit' variant='contained'>
              Submit
            </LoadingButton>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};
