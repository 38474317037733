import { useContext } from 'react';
import { MapContext } from 'contexts/MapContext';

const useMaps = () => {
  const context = useContext(MapContext);
  if (!context) throw new Error('context must be use inside provider');
  return context;
};

export default useMaps;
