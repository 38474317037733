import { createBrowserRouter, ErrorResponse, RouteObject, useRouteError } from 'react-router-dom';
import DashboardLayout from 'pages/dashboard/Dashboard';
import MainLayout from 'pages/main/Main';
import AuthGuard from 'utils/route-guard/AuthGuard';
import NavMotion from 'components/shared/NavMotion';
import GuestGuard from 'utils/route-guard/GuestGuard';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import { ClientTitle } from 'components/shared/ClientTitle';
import logger from 'utils/logger';
import { AppRoles } from 'entities/enums';
import { AppIcons } from 'types/icons';

// views
import Login from 'pages/authentication/Login';
import ForgotPassword from 'pages/authentication/ForgotPassword';
import CheckMail from 'pages/authentication/CheckMail';
import ResetPassword from 'pages/authentication/ResetPassword';
import Error404 from 'pages/Error404';
import Error500 from 'pages/Error500';
import UnderConstruction from 'pages/UnderConstruction';
import Landing from 'pages/landing';
import Dashboard from 'views/Dashboard';
import Pulse from 'views/Pulse';
import Maps, { loader as mapLoader } from './views/Maps';
import { SignalerView } from 'views/Signaler';
import { InspectionsView } from 'views/Inspections';
import { InspectionView, loader as InspectionLoader } from './views/Inspection';

// TODO refactor
import Settings from 'views/Settings';
import Photo from './views/Photo';
import Photos from 'views/Photos';
import { SignalTypesView } from 'views/SignalTypes';

// View with collection and base form view logic
import { ContactsView, loader as contactLoader } from 'views/Contacts';
import { ContactGroupsView, loader as contactGroupLoader } from 'views/ContactGroups';
import { DevicesView, loader as DeviceLoader } from './views/Devices';
import { EventsView, loader as eventLoader } from 'views/Events';
import { InspectionTypesView, loader as inspectionTypeLoader } from 'views/InspectionTypes';
import { PulseRelaysView, loader as pulseRelayLoader } from 'views/PulseRelays';
import { ShiftsView, loader as shiftLoader } from 'views/Shifts';
import { SystemsView, loader as systemLoader } from 'views/Systems';
import { BeaconsView, loader as beaconLoader } from 'views/Beacons';
import { BuildingsView, loader as buildingLoader } from 'views/Buildings';
import { CalendarView, loader as calendarItemLoader } from 'views/Calendar';
import { MapsEditView, loader as mapEditLoader } from 'views/MapsEdit';

export type AuthRouteChild = RouteObject & {
  roles?: AppRoles[];
};

type AuthRouteObject = RouteObject & {
  children?: AuthRouteChild[];
};

export const ErrorBoundary = () => {
  const error = useRouteError() as ErrorResponse;
  logger.logError(error);
  return error.status === 404 ? <Error404 /> : <Error500 />;
};

const LandingRoutes: RouteObject = { path: '/', element: <Landing />, ErrorBoundary: ErrorBoundary };

const LoginRoutes: RouteObject = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    </NavMotion>
  ),
  ErrorBoundary: ErrorBoundary,
  children: [
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot',
      element: <ForgotPassword />,
    },
    {
      path: '/check-mail',
      element: <CheckMail />,
    },
    {
      path: '/reset',
      element: <ResetPassword />,
    },
  ],
};

export const AuthRoutes: AuthRouteObject = {
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout />
    </AuthGuard>
  ),
  ErrorBoundary: ErrorBoundary,
  children: [
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/calendar/:id?',
      element: <CalendarView />,
      loader: calendarItemLoader,
    },
    {
      path: '/pulse',
      element: <Pulse />,
      roles: [AppRoles.Admin, AppRoles.Client, AppRoles.Inspector],
    },
    {
      path: '/settings',
      element: <Settings />,
      roles: [AppRoles.Admin],
    },
    {
      path: '/maps',
      element: <Maps />,
    },
    {
      path: '/maps/:address?',
      element: <Maps />,
      loader: mapLoader,
    },
    {
      path: '/mapsEdit/:id?',
      element: <MapsEditView />,
      loader: mapEditLoader,
    },
    {
      path: '/inspections',
      element: <InspectionsView />,
      roles: [AppRoles.Admin, AppRoles.Inspector],
    },
    {
      path: '/inspections/:id',
      element: <InspectionView />,
      loader: InspectionLoader,
      roles: [AppRoles.Admin, AppRoles.Inspector],
    },
    {
      path: '/events/:id?',
      element: <EventsView />,
      loader: eventLoader,
    },
    {
      path: '/systems/:id?',
      element: <SystemsView />,
      roles: [AppRoles.Admin, AppRoles.Inspector],
      loader: systemLoader,
    },
    {
      path: '/shifts/:id?',
      element: <ShiftsView />,
      roles: [AppRoles.Admin, AppRoles.Inspector],
      loader: shiftLoader,
    },
    {
      path: '/photos',
      element: <Photos />,
    },
    {
      path: '/photo',
      element: <Photo />,
    },
    {
      path: '/buildings/:id?',
      element: <BuildingsView />,
      roles: [AppRoles.Admin],
      loader: buildingLoader,
    },
    {
      path: '/contacts/:id?',
      element: <ContactsView />,
      roles: [AppRoles.Admin],
      loader: contactLoader,
    },
    {
      path: '/contactGroups/:id?',
      element: <ContactGroupsView />,
      roles: [AppRoles.Admin],
      loader: contactGroupLoader,
    },
    {
      path: '/signaler',
      element: <SignalerView />,
      roles: [AppRoles.Admin],
    },
    {
      path: '/relays/:id?',
      element: <PulseRelaysView />,
      roles: [AppRoles.Admin],
      loader: pulseRelayLoader,
    },
    {
      path: '/devices/:id?',
      element: <DevicesView />,
      roles: [AppRoles.Admin, AppRoles.Inspector],
      loader: DeviceLoader,
    },
    {
      path: '/types/beacons/:id?',
      element: <BeaconsView />,
      roles: [AppRoles.Admin],
      loader: beaconLoader,
    },
    {
      path: '/types/inspection/:id?',
      element: <InspectionTypesView />,
      loader: inspectionTypeLoader,
    },
    {
      path: '/types/signal',
      element: <SignalTypesView />,
      roles: [AppRoles.Admin],
    },
  ],
};

export const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'dashboard',
      // title: <ClientTitle showClient={true} />,
      //icon: AppIcons.Dashboard,
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: <FormattedMessage id='dashboard' />,
          type: 'item',
          url: '/dashboard',
          icon: AppIcons.Dashboard,
        },
        {
          id: 'pulse',
          title: <FormattedMessage id='pulse' />,
          type: 'item',
          url: '/pulse',
          icon: AppIcons.Notifications,
          roles: [AppRoles.Admin, AppRoles.Client, AppRoles.Inspector],
        },
        {
          id: 'eventLogs',
          title: <FormattedMessage id='eventLogs' />,
          type: 'item',
          url: '/events',
          icon: AppIcons.EventLogs,
        },
        {
          id: 'calendar',
          title: <FormattedMessage id='calendar' />,
          type: 'item',
          url: '/calendar',
          icon: AppIcons.CalendarMonth,
        },
        {
          id: 'reports',
          title: <FormattedMessage id='reports' />,
          type: 'item',
          url: '/reports',
          icon: AppIcons.Assessment,
          disabled: true,
        },
      ],
    },
    {
      id: 'site',
      title: <ClientTitle showSiteCode showBuildingShortName showSystem />,
      type: 'group',
      children: [
        // {
        //   id: 'alarms',
        //   title: <FormattedMessage id='alarms' />,
        //   type: 'item',
        //   url: '/alarms',
        //   icon: AppIcons.Alarm,
        // },
        {
          id: 'maps',
          title: <FormattedMessage id='maps' />,
          type: 'item',
          url: '/maps',
          icon: AppIcons.Map,
        },
        {
          id: 'inspections',
          title: <FormattedMessage id='inspections' />,
          type: 'item',
          url: '/inspections',
          icon: AppIcons.Inspections,
          roles: [AppRoles.Admin, AppRoles.Inspector],
        },
        {
          id: 'photos',
          title: <FormattedMessage id='photos' />,
          type: 'item',
          url: '/photos',
          icon: AppIcons.Collections,
        },
        // {
        //   id: 'reports',
        //   title: <FormattedMessage id='reports' />,
        //   type: 'item',
        //   url: '/report',
        //   icon: AppIcons.Assessment,

        //   disabled: true,
        // },
        // {
        //   id: 'notifications',
        //   title: <FormattedMessage id='notifications' />,
        //   type: 'item',
        //   url: '/notification',
        //   icon: AppIcons.Notifications,
        // },
      ],
    },
    {
      id: 'utilities',
      title: <FormattedMessage id='utilities' />,
      icon: AppIcons.SettingsIcon,
      type: 'group',
      children: [
        {
          id: 'camera',
          title: <FormattedMessage id='camera' />,
          type: 'item',
          url: '/photo',
          icon: AppIcons.Camera,
          disabled: true,
        },
        {
          id: 'shift',
          title: <FormattedMessage id='shifts' />,
          type: 'item',
          url: '/shifts',
          icon: AppIcons.Shifts,
          roles: [AppRoles.Admin, AppRoles.Inspector],
        },
        {
          id: 'signaler',
          title: <FormattedMessage id='signaler' />,
          type: 'item',
          url: '/signaler',
          icon: AppIcons.Signals,
          roles: [AppRoles.Admin],
        },
        {
          id: 'pulseRelays',
          title: <FormattedMessage id='pulseRelays' />,
          icon: AppIcons.Notifications,
          type: 'item',
          url: '/relays',
          roles: [AppRoles.Admin],
        },
      ],
    },
    {
      id: 'config',
      title: <FormattedMessage id='config' />,
      type: 'group',
      icon: AppIcons.Tune,
      roles: [AppRoles.Admin],
      children: [
        {
          id: 'mapsEdit',
          title: <FormattedMessage id='mapsEdit' />,
          icon: AppIcons.MapEdit,
          type: 'item',
          url: '/mapsEdit',
          roles: [AppRoles.Admin],
        },
        {
          id: 'buildings',
          title: <FormattedMessage id='buildings' />,
          type: 'item',
          icon: AppIcons.Building,
          url: '/buildings',
          roles: [AppRoles.Admin],
        },
        {
          id: 'contacts',
          title: <FormattedMessage id='contacts' />,
          type: 'item',
          icon: AppIcons.PersonAddAlt1,
          url: '/contacts',
          roles: [AppRoles.Admin],
        },
        {
          id: 'contactGroupss',
          title: <FormattedMessage id='contactGroups' />,
          type: 'item',
          icon: AppIcons.GroupAdd,
          url: '/contactGroups',
          roles: [AppRoles.Admin],
        },
        {
          id: 'systems',
          title: <FormattedMessage id='systems' />,
          type: 'item',
          icon: AppIcons.System,
          url: '/systems',
          roles: [AppRoles.Admin],
        },
        {
          id: 'devices',
          title: <FormattedMessage id='devices' />,
          type: 'item',
          icon: AppIcons.Device,
          url: '/devices',
          roles: [AppRoles.Admin],
        },
        {
          id: 'types',
          title: <FormattedMessage id='types' />,
          type: 'collapse',
          icon: AppIcons.Type,
          url: '/types',
          roles: [AppRoles.Admin],
          children: [
            {
              id: 'beacons',
              title: <FormattedMessage id='beacons' />,
              type: 'item',
              url: '/types/beacons',
              roles: [AppRoles.Admin],
            },
            {
              id: 'inspectionTypes',
              title: <FormattedMessage id='inspectionTypes' />,
              type: 'item',
              url: '/types/inspection',
              roles: [AppRoles.Admin],
            },
            {
              id: 'signalTypes',
              title: <FormattedMessage id='signalTypes' />,
              type: 'item',
              url: '/types/signal',
              roles: [AppRoles.Admin],
            },
          ],
        },
      ],
    },
  ],
};

export const router = createBrowserRouter([LandingRoutes, LoginRoutes, AuthRoutes /*, FallbackRoutes*/], {
  basename: '',
});
