import { useEffect, useRef, useState } from 'react';
import useSignals from 'hooks/useSignals';
import { FeatureGroup, useMap } from 'react-leaflet';
import logger from 'utils/logger';
import { MapMarker } from './MapMarker';
import { InspectionResult, Map, MapItem } from 'entities';
import { MapType } from '../MapContainer';
import useInspection from 'hooks/useInspection';
import useAxios from 'hooks/useAxios';
import { MapItemVM } from 'entities/viewModels';
import useMaps from 'hooks/useMaps';
import { deviceMapIds, isDeviceLabel, renderDevicesLayer } from 'helpers/LayerHelper';
import useAppContext from 'hooks/useAppContext';

type MapMarkerLayerProps = {
  map?: Map;
  mapType: MapType;
  zoom: number;
  layerItems?: MapItem[];
};

export const MapMarkerLayer = ({ map, mapType, zoom, layerItems }: MapMarkerLayerProps) => {
  const lmap = useMap();
  const { get } = useAxios();
  const { states } = useSignals();
  const { context } = useAppContext();
  const { inspection } = useInspection();

  // needed for edit view
  const { mapItems, setMapItems } = useMaps();
  const markersRef = useRef(null); //<MarkerLayer>(null);

  const [iconSize, setIconSize] = useState(5);
  const [items, setItems] = useState<MapItemVM[]>();
  const [results, setResults] = useState<InspectionResult[]>();

  useEffect(() => {
    // TODO implement
    // if (mapType === 'inspection') setResults(inspection?.results);
  }, [mapType, inspection]);

  useEffect(() => {
    const size = (zoom <= 3 ? 1 : zoom <= 5 ? 2 : zoom <= 6 ? 3 : zoom <= 7 ? 5 : 10) * zoom;
    logger.log(`ZOOM:${zoom} ICONSIZE: ${size}px`);
    setIconSize(size);
  }, [zoom, map]);

  useEffect(() => {
    if (map)
      get<MapItemVM[]>(`/api/mapitem/${context?.systemId}/${map.id}/items`).then((_items) => {
        // if (mapType === 'edit' && deviceMapIds.includes(map.id)) renderDevicesLayer(map.id, lmap, _items);
        // else setItems([]);
      });
    else setItems([]);
  }, [map, states]);

  useEffect(() => {
    if (mapItems) setItems(mapItems);
  }, [mapItems]);

  useEffect(() => {
    if (layerItems && layerItems.length > 0) setItems([...(items ?? []), ...layerItems]);
    else if (!deviceMapIds.includes(map?.id ?? 0)) get<MapItemVM[]>(`/api/mapitem/${context?.systemId}/${map?.id}/items`).then(setItems);
    else setItems([]);
  }, [layerItems]);

  return (
    <FeatureGroup ref={markersRef} interactive={true}>
      {items?.map((m) => (
        <MapMarker
          item={m}
          key={m.id}
          zoom={zoom}
          mapType={mapType}
          size={iconSize}
          result={results?.find((r) => r.deviceID === m.deviceId)}
          signal={states?.find((r) => r.signalActivated?.deviceID === m.deviceId)}
        />
      ))}
    </FeatureGroup>
  );
};
