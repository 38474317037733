import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Contact } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import { UserType, NotificationType, CarrierType, AppRoles } from 'entities/enums';

const InitialState: Contact = {
  id: 0,
  name: '',
  role: '',
  title: '',
  company: '',
  phone: '',
  email: '',
  carrier: '',
  systems: [],
  userType: UserType.Contact,
  notificationType: NotificationType.All,
};

type ContactFormProps = {
  entity?: Contact;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Contact>;
};

export const ContactForm = ({ disabled, entity, onCancel, onSubmit }: ContactFormProps) => {
  const methods = useForm<Contact>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const userType = methods.watch('userType');

  const formSchema: FieldAttributes[] = [
    {
      label: 'User Type',
      name: 'userType',
      type: FieldType.SELECT,
      options: Object.keys(UserType)
        .filter((t) => isNaN(parseInt(t)))
        .map((t, i) => {
          return { label: t, value: i };
        }),
      control: methods.control,
    },
    {
      label: 'Email',
      name: 'email',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Company',
      name: 'company',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/companies',
      control: methods.control,
    },
    {
      label: 'Title',
      name: 'title',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Notification Preference',
      name: 'notificationType',
      type: FieldType.SELECT,
      options: Object.keys(NotificationType)
        .filter((t) => isNaN(parseInt(t)))
        .map((t, i) => {
          return { label: t, value: i };
        }),
      control: methods.control,
    },
    {
      label: 'Phone',
      name: 'phone',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Carrier',
      name: 'carrier',
      type: FieldType.SELECT,
      options: Object.keys(CarrierType)
        .filter((t) => isNaN(parseInt(t)))
        .map((type) => {
          return { label: type, value: type };
        }),
      control: methods.control,
    },
    {
      label: 'Role',
      name: 'role',
      type: FieldType.SELECT,
      options: Object.keys(AppRoles)
        .filter((t) => isNaN(parseInt(t)))
        .map((t, i) => {
          return { label: t, value: i };
        }),
      control: methods.control,
      hidden: userType === UserType.Contact,
    },
    {
      label: 'Systems',
      name: 'systems',
      type: FieldType.MULTISELECT,
      data: '/api/system/query',
      control: methods.control,
      hidden: userType === UserType.Contact,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Contact) => onSubmit(d)} />;
};
