import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { UserType } from 'entities/enums/UserType';
import { ReactNode } from 'react';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { Contact } from 'entities';
import { NotificationType } from 'entities/enums';

export default function ContactsTable() {
  const columns: GridColDef<Contact>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/contacts');
      },
    },
    // { field: 'id', headerName: 'ID', width: 100, filterable: false },
    { field: 'name', headerName: 'Name', width: 150, filterable: false },
    { field: 'company', headerName: 'Company', width: 150, filterable: false },
    { field: 'email', headerName: 'E-Mail', width: 300, filterable: false },
    { field: 'phone', headerName: 'Phone', width: 150, filterable: false },
    {
      field: 'notificationType',
      headerName: 'Notify By',
      width: 170,
      filterable: false,
      renderCell(params) {
        return params.value === NotificationType.All ? 'Email & SMS' : NotificationType[params.value];
      },
    },
    {
      field: 'userType',
      headerName: 'Type',
      width: 170,
      filterable: false,
      renderCell(params) {
        const role = params.row.role ? `[${params.row.role}]` : '';
        return `${UserType[params.value]} ${role}`.trim();
      },
    },
  ];

  return <DataGrid columnDefinitions={columns} dataUrl='/api/contact/query' hideFooter={false} hideHeader={false} />;
}
