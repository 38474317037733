import { Entity } from 'entities/base/Entity';

export const exportJson = (name: string, data: Entity[]) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
  const link = document.createElement('a');
  link.href = jsonString;
  link.download = `${name}.json`;
  link.click();
};

export const exportFile = (data: BlobPart, fileName: string) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data]);

  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};
