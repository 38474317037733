import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { LookupFieldAttributes } from '.';
import { NamedEntity } from '../../entities/base/Entity';
import useAxios from 'hooks/useAxios';

export const LookupField: React.FC<LookupFieldAttributes> = ({ label, name, nonLazy, dataUrl, disabled, control, description }) => {
  const { getPaged } = useAxios();

  const [loading, setLoading] = useState(true);

  const { register, setValue } = useFormContext();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<NamedEntity[]>([]);

  useEffect(() => {
    if (!nonLazy && searchValue.length > 0) {
      getPaged<NamedEntity[]>(dataUrl, { page: 0, pageSize: 100, orderBy: 'name asc', search: searchValue }).then((r) => setOptions(r?.items ?? []));
    } else setOptions([]);
  }, [searchValue]);

  useEffect(() => {
    if (nonLazy) getPaged<NamedEntity[]>(dataUrl, { page: 0, pageSize: 100, orderBy: 'name asc' }).then((r) => setOptions(r?.items ?? []));
  }, []);

  return (
    <section>
      <Controller
        name={name}
        control={control}
        //rules={{ required: true }}
        disabled={disabled}
        render={({ field }) => (
          <Autocomplete
            {...field}
            {...(register(name),
            {
              //setvalueas: (value: any) => value || undefined,
            })}
            size='small'
            options={options}
            freeSolo={true}
            disabled={disabled}
            value={field.value ?? null}
            style={{ width: '100%', margin: '5px' }}
            loading={loading}
            getOptionKey={(o) => o.id}
            loadingText={!nonLazy && (searchValue.length >= 3 ? 'Searching...' : 'Type at least 3 characters to search')}
            renderInput={(params) => (
              <TextField helperText={description ?? ''} autoComplete='off' {...params} label={label} itemID={params.id} onChange={(e) => setSearchValue(e.target.value)} />
            )}
            getOptionLabel={(option) => options.find((o) => o.id === option)?.name ?? option.name ?? ''}
            onChange={(e, v) => {
              setValue(name, v?.id ?? v);
              return v;
            }}
          />
        )}
      />
    </section>
  );
};
