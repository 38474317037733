import { Menu } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { LatLng } from 'leaflet';

export interface MenuItemProps {
  disabled?: boolean;
  onClick: () => void;
}

export type MouseEventProps = { event: React.MouseEvent; position: LatLng };

type ContextMenuProps = {
  children: ReactNode;
  mouseEvent?: MouseEventProps;
};

type ContextMenuEventProps = {
  mouseX: number;
  mouseY: number;
  event: React.MouseEvent<Element, MouseEvent>;
};

const ContextMenu = ({ children, mouseEvent }: ContextMenuProps) => {
  const [contextMenu, setContextMenu] = useState<ContextMenuEventProps>();

  const handleContextMenuClose = () => {
    setContextMenu(undefined);
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    // console.log('handleContextMenu:', event);
    //@ts-ignore
    event.preventDefault();
    setContextMenu({
      //@ts-ignore
      mouseX: event.clientX,
      //@ts-ignore
      mouseY: event.clientY,
      //@ts-ignore
      event: event,
    });
  };

  useEffect(() => {
    if (mouseEvent) handleContextMenu(mouseEvent.event);
    else handleContextMenuClose();
  }, [mouseEvent]);

  return (
    <Menu
      open={contextMenu !== undefined}
      onClose={handleContextMenuClose}
      anchorReference='anchorPosition'
      anchorPosition={contextMenu ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
    >
      {children}
    </Menu>
  );
};

export default ContextMenu;
