import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatagridFieldAttributes } from '.';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useEffect } from 'react';

import { trimTrailingSlash } from '../../utils/extensions';
import logger from '../../utils/logger';
import { Box } from '@mui/material';
import useAxios from 'hooks/useAxios';

const initialRows: GridRowsProp = [];

export const DatagridField: React.FC<DatagridFieldAttributes> = ({ label, name, type, control, dataUrl, dataIdField }) => {
  const { get } = useAxios();
  const { register } = useFormContext();

  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleAddClick = () => {
    const id = -1;
    setRows((oldRows) => [...oldRows, { id: id, name: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label='Cancel' className='textPrimary' onClick={handleCancelClick(id)} color='inherit' />,
          ];
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label='Edit' className='textPrimary' onClick={handleEditClick(id)} color='inherit' />,
          <GridActionsCellItem icon={<DeleteIcon />} label='Delete' onClick={handleDeleteClick(id)} color='inherit' />,
        ];
      },
    },
    {
      field: 'name',
      headerName: 'Question',
      width: 500,
      editable: true,
      sortable: false,
    },
  ];

  const editToolbar = () => (
    <GridToolbarContainer sx={{ mb: 1 }}>
      <Button variant='outlined' startIcon={<AddIcon />} onClick={handleAddClick}>
        Add question
      </Button>
    </GridToolbarContainer>
  );

  useEffect(() => {
    // console.log("formValues:", control._formValues[name])
    setRows(control._formValues[name]);
  }, [control._formValues, name]);

  useEffect(() => {
    const id = control._formValues[dataIdField];
    if (id) {
      get<GridValidRowModel[]>(`${trimTrailingSlash(dataUrl)}/${id}`).then(setRows);
    }
  }, []);

  return (
    <section>
      <Controller
        name={name}
        control={control}
        //rules={{ required: true }}
        render={({ field }) => {
          return (
            <Box
              component='div'
              sx={{
                width: '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                {...field}
                {...register(name)}
                rows={rows} //field.value}
                columns={columns}
                autoHeight={true}
                editMode='row'
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: editToolbar,
                }}
                slotProps={{
                  toolbar: { setRows, setRowModesModel },
                }}
                hideFooter={true}
              />
            </Box>
          );
        }}
      />
    </section>
  );
};
