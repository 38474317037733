import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import ContactGroupsTable from 'collections/ContactGroupsTable';
import { ContactGroup } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { ContactGroupForm } from 'forms/ContactGroupForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const ContactGroupsView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post } = useAxios();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<ContactGroup>();

  const handleSubmit: SubmitHandler<ContactGroup> = (data) => {
    logger.log('CONTACTGROUP SUBMIT', data);
    // TODO post data
    setShowForm(false);
  };

  useEffect(() => {
    if (id && id > 0) {
      get<ContactGroup>(`/api/contactGroup/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Edit Contact Group: ${entity.name}` : 'New Contact Group' : <ClientTitle prefix={'Contact groups for'} showClient />}
      secondary={
        !showForm && (
          <Button
            variant='outlined'
            startIcon={<AddIcon />}
            onClick={() => {
              navigate('/contactGroups/0');
              setShowForm(true);
            }}
          >
            Add Contact Group
          </Button>
        )
      }
    >
      {showForm ? (
        <ContactGroupForm
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate('/contactGroups');
            setShowForm(false);
          }}
        />
      ) : (
        <ContactGroupsTable />
      )}
    </MainCard>
  );
};
