import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemProps } from '../ContextMenu';

export const DeleteItem = ({ disabled, onClick }: MenuItemProps) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <ListItemIcon>
        <Delete fontSize='small' />
      </ListItemIcon>
      <ListItemText>Delete item</ListItemText>
    </MenuItem>
  );
};
