import Loader from 'components/shared/Loader';
import { Inspection, InspectionResult } from 'entities';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap } from '../types/actionMap';
import { InspectionState } from 'entities/enums/InspectionState';
import useAxios from 'hooks/useAxios';
import { InspectionResultType } from 'views/Inspection';
import useNotification from 'hooks/useNotification';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { updateArray } from 'utils/array';

export type InspectionResultData = { result: InspectionResult; type: InspectionResultType };

type InspectionProviderProps = {
  children: ReactNode;
};

interface InspectionContextState {
  inspection?: Inspection;
  drawerData?: InspectionResultData;
}

enum InspectionActionTypes {
  SetInspection,
  SetDrawerData,
}

interface InspectionContextType extends InspectionContextState {
  handleState: (id?: number) => Promise<void>;
  getInspection: (id?: number) => Promise<void>;
  setDrawerData: (data?: InspectionResultData) => Promise<void>;
  handleInspectionSubmit: (data: Inspection) => Promise<void>;
  handleInspectionResultSubmit: (data: InspectionResult) => Promise<void>;
}

type InspectionPayload = {
  [InspectionActionTypes.SetInspection]: Inspection | undefined;
  [InspectionActionTypes.SetDrawerData]: InspectionResultData | undefined;
};

type InspectionActions = ActionMap<InspectionPayload>[keyof ActionMap<InspectionPayload>];

const inspectionReducer = (state: InspectionContextState, action: InspectionActions): InspectionContextState => {
  switch (action.type) {
    case InspectionActionTypes.SetInspection:
      return { ...state, inspection: action.payload };
    case InspectionActionTypes.SetDrawerData:
      return { ...state, drawerData: action.payload };
    default:
      return { ...state };
  }
};

export const InspectionContext = createContext<InspectionContextType | null>(null);

export const InspectionProvider = ({ children }: InspectionProviderProps) => {
  const { get, patch } = useAxios();
  const { success } = useNotification();
  const { inspectionStateHandler, inspectionResultHandler } = BroadcastHelper();

  const [state, dispatch] = useReducer(inspectionReducer, {});

  const getInspection = async (id?: number) => {
    if (id && id > 0) {
      const inspection = await get<Inspection>(`/api/inspection/${id}`);
      dispatch({ type: InspectionActionTypes.SetInspection, payload: inspection });
    } else dispatch({ type: InspectionActionTypes.SetInspection, payload: undefined });
  };

  const handleState = async (id?: number) => {
    if (!id) return;
    const item = state.inspection?.id === id ? state.inspection : await get<Inspection>(`/api/inspection/${id}`);
    if (item && !item.endDate) {
      const alternateState = item.state === InspectionState.Paused ? InspectionState.InProgress : InspectionState.Paused;
      await patch(`/api/inspection/${item.id}/state/${alternateState}`);
    }
  };

  const setDrawerData = (item?: InspectionResultData) => {
    dispatch({ type: InspectionActionTypes.SetDrawerData, payload: item });
    return Promise.resolve();
  };

  const handleInspectionSubmit = async (data: Inspection) => {
    //TODO implement formatter
    data.type = undefined;
    // console.log('SUBMITTING', data);
    await patch(`/api/inspection/${data.id}`, data).then(() => {
      setDrawerData(undefined);
      success(`Successfully updated ${data.name}`);
      // get<Inspection>(`/api/inspection/${id}`).then(setInspection);
    });
  };

  const handleInspectionResultSubmit = async (data: InspectionResult) => {
    // const address = data.device?.address;
    // data.device = undefined;
    // data.signals = [];
    // console.log('SUBMITTING', data);
    await patch(`/api/inspectionresult/${data.id}`, data).then(async () => {
      setDrawerData(undefined);
      success(`Successfully updated`);
    });
  };

  useEffect(() => {
    inspectionStateHandler(async (inspectonState: { id: number; state: InspectionState }) => {
      // refresh inspection if this is current
      if (inspectonState.id === state.inspection?.id) {
        await getInspection(inspectonState.id);
      }
    });
    inspectionResultHandler(async (inspectionResult: InspectionResult) => {
      // TODO implement
      // if (state.inspection && state.inspection.id == inspectionResult.inspectionID && state.inspection?.results)
      //   dispatch({ type: InspectionActionTypes.SetInspection, payload: { ...state.inspection, results: updateArray(state.inspection?.results, inspectionResult) } });
    });
  }, []);

  if (!state) {
    return <Loader />;
  }

  return (
    <InspectionContext.Provider value={{ ...state, handleState, getInspection, handleInspectionSubmit, handleInspectionResultSubmit, setDrawerData }}>
      {children}
    </InspectionContext.Provider>
  );
};
