import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { System } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import { SystemState } from 'entities/enums';

const InitialState: System = {
  state: SystemState.Normal,
  buildingID: 0,
  name: '',
  id: 0,
  type: undefined,
  typeID: 0,
};

type SystemFormProps = {
  entity?: System;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<System>;
};

export const SystemForm = ({ disabled, entity, onCancel, onSubmit }: SystemFormProps) => {
  const methods = useForm<System>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
      span: 12,
    },
    {
      label: 'Type',
      name: 'typeId',
      type: FieldType.LOOKUP,
      control: methods.control,
      required: true,
      dataUrl: '/api/systemType/query',
    },
    {
      label: 'Buildings',
      name: 'buildings',
      type: FieldType.LOOKUP,
      control: methods.control,
      required: true,
      dataUrl: '/api/building/query',
    },
    {
      label: 'Contact groups',
      name: 'contactGroups',
      type: FieldType.MULTISELECT,
      control: methods.control,
      required: true,
      data: '/api/contactGroup/query',
    },
    {
      label: 'Signal types',
      name: 'signalTypes',
      type: FieldType.MULTISELECT,
      control: methods.control,
      required: true,
      data: '/api/contactGroup/query',
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: System) => onSubmit(d)} />;
};
