import { Button } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { CalendarItem } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { CalendarForm } from 'forms/CalendarForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import Calendar from 'components/calendar';
import { AddAlarm } from '@mui/icons-material';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const CalendarView = () => {
  const params = useLoaderData() as string;

  const navigate = useNavigate();
  const { get, post } = useAxios();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<CalendarItem>();

  const handleSubmit: SubmitHandler<CalendarItem> = (data) => {
    logger.log('CalendarItem SUBMIT', data);
    //post<Calendar>(`/api/Calendar`, data).then(() => navigate('/'));
    setShowForm(false);
  };

  useEffect(() => {
    if (id && id > 0) {
      get<CalendarItem>(`/api/calendar/query/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `Edit Calendar: ${entity.name}` : 'New Calendar' : <ClientTitle prefix={'Calendar for'} showClient />}
      secondary={
        !showForm && (
          <Button
            variant='outlined'
            startIcon={<AddAlarm />}
            onClick={() => {
              navigate('/calendar/0');
              setShowForm(true);
            }}
          >
            Add Event
          </Button>
        )
      }
    >
      {showForm ? (
        <CalendarForm
          entity={entity}
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate('/calendar');
            setShowForm(false);
          }}
        />
      ) : (
        <Calendar />
      )}
    </MainCard>
  );
};
