import Loader from 'components/shared/Loader';
import { Map, MapItem, MapLayers as IMapLayers } from 'entities';
import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { ActionMap } from 'types/actionMap';
import { MarkerType } from 'components/maps/base/MapMarker';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';
import { MapItemVM } from 'entities/viewModels';
import { LatLngBounds } from 'leaflet';

export type LayersMap = {
  [key: string]: boolean;
};

type MapProviderProps = {
  children: ReactNode;
};

interface MapContextState {
  markerType: MarkerType;
  searchQuery?: string;
  map?: Map;
  maps?: Map[];

  mapItem?: MapItem;
  markerItem?: MapItem;
  mapItems?: MapItemVM[];

  layers: LayersMap;
  layer?: IMapLayers;
  layerItems?: MapItemVM[];

  devicesBounds?: LatLngBounds;
}

interface MapContextType extends MapContextState {
  addMarker: (mapItem?: MapItem) => void;

  setLayer: (layer?: IMapLayers) => void;
  setLayers: (layers: LayersMap) => void;
  setLayerItems: (items?: MapItemVM[]) => void;
  setMap: (mapId?: number) => void;
  setDevicesBounds: (bunds?: LatLngBounds) => void;
  setMapItems: (items?: MapItemVM[]) => void;
  setSearchQuery: (query?: string) => void;
  // showOnMap: (address?: string) => Promise<void>;

  moveToItem: (item?: MapItem) => Promise<void>;
  hasMapItem: (address?: string) => Promise<MapItem | undefined>;
  showMarkerType: (markerType: MarkerType) => void;
  refreshMap: () => Promise<void>;
}

enum MapActionTypes {
  Search = 'SEARCH',
  Layer = 'LAYER',
  Layers = 'LAYERS',
  LayerItems = 'LAYERITEMS',
  AddMarker = 'ADD_MARKER',
  SetMap = 'SET_MAP',
  SetMaps = 'SET_MAPS',
  SetMapItems = 'SET_MAP_ITEMS',
  SetDevicesBounds = 'SET_DEVICES_BOUNDS',
  RefreshMap = 'REFRESH_MAP',
  MoveToItem = 'MOVE_TO_MAPITEM',
  OpenPopup = 'OPEN_POPUP',
  MarkerType = 'MARKER_TYPE',
}

type MapPayload = {
  [MapActionTypes.Search]: string | undefined;

  [MapActionTypes.Layer]: IMapLayers | undefined;
  [MapActionTypes.Layers]: LayersMap;
  [MapActionTypes.LayerItems]: MapItemVM[] | undefined;
  [MapActionTypes.AddMarker]: MapItem | undefined;
  [MapActionTypes.SetMap]: number | undefined;
  [MapActionTypes.SetMaps]: Map[] | undefined;
  [MapActionTypes.SetDevicesBounds]: LatLngBounds | undefined;
  [MapActionTypes.SetMapItems]: MapItemVM[] | undefined;
  [MapActionTypes.MoveToItem]: { map?: Map; item?: MapItem } | undefined;
  [MapActionTypes.OpenPopup]: number | undefined;
  [MapActionTypes.MarkerType]: MarkerType;
};

type MapActions = ActionMap<MapPayload>[keyof ActionMap<MapPayload>];

const InitialState: MapContextState = {
  markerType: 'All',
  map: undefined,
  maps: undefined,
  mapItem: undefined,
  markerItem: undefined,
  mapItems: undefined,
  layers: {},
  layer: undefined,
  layerItems: undefined,
  devicesBounds: undefined,
};

const mapReducer = (state: MapContextState, action: MapActions): MapContextState => {
  switch (action.type) {
    case MapActionTypes.Search:
      return { ...state, searchQuery: action.payload };
    case MapActionTypes.Layer:
      return { ...state, layer: action.payload };
    case MapActionTypes.Layers:
      return { ...state, layers: action.payload };
    case MapActionTypes.LayerItems:
      return { ...state, layerItems: action.payload };
    case MapActionTypes.SetMapItems:
      return { ...state, mapItems: action.payload };
    case MapActionTypes.AddMarker:
      return { ...state, markerItem: action.payload };
    case MapActionTypes.SetMap:
      return { ...state, map: state?.maps?.find((m) => m.id === action.payload) };
    case MapActionTypes.SetMaps:
      return { ...state, maps: action.payload, map: undefined };
    case MapActionTypes.SetDevicesBounds:
      return { ...state, devicesBounds: action.payload };
    // case MapActionTypes.ShowOnMap:
    //   return { ...state, map: action.payload?.map ?? state.map, mapItem: action.payload?.item };
    case MapActionTypes.MoveToItem:
      return { ...state, map: action.payload?.map ?? state.map, mapItem: action.payload?.item };
    case MapActionTypes.MarkerType:
      return { ...state, markerType: action.payload };
    default:
      return { ...state };
  }
};

export const MapContext = createContext<MapContextType | null>(null);

export const MapProvider = ({ children }: MapProviderProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();
  const [state, dispatch] = useReducer(mapReducer, InitialState);

  const setSearchQuery = (query?: string) => dispatch({ type: MapActionTypes.Search, payload: query });

  const setLayer = (layer?: IMapLayers) => dispatch({ type: MapActionTypes.Layer, payload: layer });

  const setLayers = (layers: LayersMap) => dispatch({ type: MapActionTypes.Layers, payload: layers });

  const setLayerItems = (items?: MapItemVM[]) => dispatch({ type: MapActionTypes.LayerItems, payload: items });

  const setMapItems = (items?: MapItemVM[]) => dispatch({ type: MapActionTypes.SetMapItems, payload: items });

  const setMap = (mapId?: number) => dispatch({ type: MapActionTypes.SetMap, payload: mapId });

  const setDevicesBounds = (bounds?: LatLngBounds) => dispatch({ type: MapActionTypes.SetDevicesBounds, payload: bounds });

  const moveToItem = async (selected?: MapItem) => {
    // if (selected) {
    const map = selected ? state.maps?.find((m) => m.id === selected?.mapID) : state.map;
    dispatch({ type: MapActionTypes.MoveToItem, payload: { map: map ?? state.map, item: selected } });
    // }
  };

  const hasMapItem = async (address?: string) => {
    if (!address) return undefined;
    return await get<MapItem>(`/api/mapitem/${context?.systemId}/address/${address}`);
  };

  const refreshMap = async () => {
    if (state.map?.id) {
      const mapItems = await get<MapItem[]>(`/api/mapitem/${state.map?.id}/items`);
      dispatch({ type: MapActionTypes.SetMapItems, payload: mapItems });
    }
  };

  const addMarker = (mapItem?: MapItem) => {
    dispatch({ type: MapActionTypes.AddMarker, payload: mapItem });
  };

  const showMarkerType = (markerType: MarkerType) => {
    dispatch({ type: MapActionTypes.MarkerType, payload: markerType });
  };

  useEffect(() => {
    console.log('MAPSHOOK', context?.maps);
    // get maps from AppContext
    dispatch({ type: MapActionTypes.SetMaps, payload: context?.maps });
  }, [context, context?.maps]);

  if (!state) {
    return <Loader />;
  }

  return (
    <MapContext.Provider
      value={{ ...state, setSearchQuery, setLayer, setLayerItems, setLayers, setMapItems, setMap, setDevicesBounds, moveToItem, refreshMap, addMarker, showMarkerType, hasMapItem }}
    >
      {children}
    </MapContext.Provider>
  );
};
