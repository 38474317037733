import { OpenWith } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { MenuItemProps } from '../ContextMenu';

interface MoveMenuItemProps extends MenuItemProps {
  isMoving?: boolean;
}

export const MoveItem = ({ disabled, onClick, isMoving }: MoveMenuItemProps) => {
  return (
    <MenuItem onClick={onClick} disabled={disabled}>
      <ListItemIcon>
        <OpenWith fontSize='small' />
      </ListItemIcon>
      <ListItemText>{isMoving ? 'Cancel move' : 'Move'} item</ListItemText>
    </MenuItem>
  );
};
