import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';

type EventItemTableProps = {
  pageSizeOptions?: number[];
};

export default function EventItemsTable({ pageSizeOptions }: EventItemTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/events');
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Incident',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid hideHeader={true} orderBy={'date desc'} pageSizeOptions={pageSizeOptions} columnDefinitions={columns} />;
}
