import { Entity } from 'entities/base/Entity';

export function addOrUpdateArray<T extends Entity>(array: T[], element: T): T[] {
  const i = array.findIndex((e) => e.id === element.id);
  if (i > -1) array[i] = element;
  else array.push(element);

  return array;
}

export function updateArray<T extends Entity>(array: T[], element: T): T[] {
  const i = array.findIndex((e) => e.id === element.id);
  if (i > -1) array[i] = element;
  return array;
}
