import { useVersion } from 'hooks/useVersion';
import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MainLayout = () => {
  useVersion(); // check version and reload
  return <Outlet />;
};

export default MainLayout;
