import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, List, ListItem, ListItemText, ListSubheader, Radio, RadioGroup, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ListFieldAttributes } from '.';
import { ShiftQuestion } from '../../entities';
import { groupBy } from '../../utils/extensions';
import useAxios from 'hooks/useAxios';

export const ListField: React.FC<ListFieldAttributes> = ({ label, name, type, data, disabled, control }) => {
  const { get } = useAxios();
  const { register } = useFormContext();

  const [items, setItems] = useState<[string?, ShiftQuestion[]?][]>();

  const renderQuestion = (q: ShiftQuestion) => {
    return (
      <Box
        component={'div'}
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: '4fr', lg: '1fr 1fr 2fr' },
          alignItems: 'center',
          gap: 2,
          width: '100%',
          mt: 2,
        }}
      >
        <b>{q.name}</b>
        <RadioGroup row>
          <FormControlLabel value={true} control={<Radio size='small' />} label={q.trueLabel} />
          <FormControlLabel value={false} control={<Radio size='small' />} label={q.falseLabel} />
        </RadioGroup>
        <TextField autoComplete='off' size={'small'} disabled={true} />
      </Box>
    );
  };

  useEffect(() => {
    if (typeof data === 'string') get<ShiftQuestion[]>(data).then((d) => setItems(groupBy(d, (q) => q.groupLabel)));
    // else setItems(data);
  }, [data]);

  return (
    <Controller
      name={name}
      control={control}
      //rules={{ required: true }}
      render={({ field }) => (
        <Box component='div' style={{ width: '100%', margin: '5px' }}>
          {items &&
            items.map((group) => (
              <Box
                component={'div'}
                sx={{
                  width: '100%',
                  mt: 5,
                }}
              >
                <b>{group[0]}</b>
                {group[1]?.map(renderQuestion)}
              </Box>
            ))}
        </Box>
      )}
    />
  );
};
