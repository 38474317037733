import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { Building } from 'entities';
import useAppContext from 'hooks/useAppContext';

export default function BuildingsTable() {
  const { context } = useAppContext();
  const columns: GridColDef<Building>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/buildings');
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 200,
    },
    {
      field: 'shortName',
      headerName: 'ShortName',
      filterable: false,
      width: 150,
    },
    {
      field: 'site.name',
      headerName: 'Site',
      filterable: false,
      width: 300,
      renderCell(params) {
        return 'TODO ENRICH';
        // return params.row.site?.name ?? '';
      },
    },
    {
      field: 'site.timezone',
      headerName: 'Timezone',
      filterable: false,
      width: 150,
      renderCell(params) {
        return 'TODO ENRICH';
        // return params.row.site?.timeZoneInfoId ?? '';
      },
    },
  ];

  return <DataGrid dataUrl={`/api/building/query/${context?.clientId}`} hideHeader={false} orderBy={'name asc'} columnDefinitions={columns} />;
}
