import React from 'react';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import { Autocomplete, Box, Button, Checkbox, Grid2 as Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useNotification from 'hooks/useNotification';
import useAxios from 'hooks/useAxios';
import { ContactGroup, Pulse } from 'entities';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PulseList from 'collections/PulseList';

const PulseView = () => {
  const { get, post } = useAxios();
  const { success } = useNotification();
  const [preview, setPreview] = useState(false);

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [receipts, setReceipts] = useState<ContactGroup[]>();

  const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  const handleOnSend = () => {
    // const d = new Date();
    // setMessage('');
  };

  const onPreviewClick = () => {
    setPreview(!preview);
  };

  const onSendClick = () => {
    setPreview(false);
    var pulse: Pulse = {
      message: message,
      name: subject,
      id: 0,
      date: new Date().toISOString(),
      contactGroupIds: receipts?.map((g) => g.id) ?? [],
    };
    post<Pulse>(`/api/pulse/send/`, pulse).then(() => {
      success(`Send pulse '${subject}' `);
      setSubject('');
      setMessage('');
      setReceipts(undefined);
    });
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.key !== 'Enter') {
      return;
    }
    handleOnSend();
  };

  const renderContactGroupInfo = (group: ContactGroup): { label: string; disabled: boolean } => ({ label: group.name, disabled: false });

  useEffect(() => {
    get<ContactGroup[]>(`/api/contactGroup/query`).then(setContactGroups);
  }, []);

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Grid container spacing={gridSpacing}>
        <Grid size={{ xs: 12, md: 8 }}>
          <MainCard title={'Pulse'}>
            <Box component='div' sx={{ width: '100%', height: '100%' }}>
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  {!preview && (
                    <>
                      {/* <PulseInfo /> */}
                      <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <TextField
                          autoComplete='off'
                          variant='outlined'
                          label={'Subject'}
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          aria-describedby='search-helper-text'
                          inputProps={{ 'aria-label': 'weight' }}
                        />
                        <Autocomplete
                          multiple
                          fullWidth
                          value={receipts ?? []}
                          options={contactGroups}
                          onChange={(_, o) => setReceipts(o as ContactGroup[])}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          getOptionKey={(option) => option.id}
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            const contactInfo = renderContactGroupInfo(option);
                            return (
                              <li key={key} {...optionProps} aria-disabled={contactInfo.disabled}>
                                <Checkbox disabled={contactInfo.disabled} icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {contactInfo.label}
                              </li>
                            );
                          }}
                          renderInput={(params) => <TextField autoComplete='off' {...params} label='Contact Group(s)' />}
                        />
                        <TextField
                          autoComplete='off'
                          variant='outlined'
                          label={'Message'}
                          multiline
                          rows={4}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          aria-describedby='search-helper-text'
                          inputProps={{ 'aria-label': 'weight' }}
                        />
                        <Button
                          disabled={subject.length === 0 || (receipts ?? []).length === 0 || message.length === 0}
                          variant='contained'
                          color={'primary'}
                          onClick={onPreviewClick}
                          sx={{ px: 0.75 }}
                        >
                          {'Preview'}
                        </Button>
                      </Box>
                    </>
                  )}
                  {preview && (
                    <>
                      <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <TextField autoComplete='off' variant='outlined' label={'Subject'} value={`BeaconSuite: ${subject}`} disabled />
                        <Autocomplete
                          multiple
                          fullWidth
                          disabled={true}
                          value={receipts ?? []}
                          options={contactGroups}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.name}
                          getOptionKey={(option) => option.id}
                          renderInput={(params) => <TextField autoComplete='off' {...params} label='Contact Group(s)' />}
                        />
                        <TextField autoComplete='off' variant='outlined' label='Message' multiline rows={5} value={message} disabled />
                      </Box>
                      <Button variant='contained' onClick={onPreviewClick} sx={{ px: 0.75, mt: 2, mr: 1 }}>
                        CANCEL
                      </Button>
                      <Button disabled variant='contained' color={'secondary'} onClick={onSendClick} sx={{ px: 0.75, mt: 2 }}>
                        SEND
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </MainCard>
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <MainCard title={'Pulse History'}>
            <PulseList />
          </MainCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PulseView;
