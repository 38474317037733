import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid2';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';

const Settings = () => {
  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard title={<ClientTitle prefix={'Settings for'} showClient={true} />}></MainCard>
      </Grid>
    </Grid>
  );
};

export default Settings;
