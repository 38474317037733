import { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItem, ListItemText, Grid2 as Grid, Typography, Divider, Avatar, Badge, ListItemAvatar, CircularProgress, Autocomplete, Checkbox, TextField } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Device, DeviceType, MapItem } from 'entities';
import useAxios, { PagedResult, QueryOptions } from 'hooks/useAxios';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useMaps from 'hooks/useMaps';
import useAppContext from 'hooks/useAppContext';

type DeviceListProps = {
  type: 'Unmapped' | 'Uninspected';
};

type DeviceItemProps = {
  item: Device;
  dnd: DraggableProvided;
  isDragging: boolean;
};

type MapItemProps = {
  item: MapItem;
};

function DeviceItem({ item, dnd, isDragging }: DeviceItemProps) {
  return (
    <Fragment>
      <ListItem ref={dnd.innerRef} {...dnd.draggableProps} {...dnd.dragHandleProps}>
        <ListItemAvatar title={item.type?.name}>
          <Badge
            overlap='circular'
            //badgeContent={<AvatarStatus status={user.online_status!} />} // TODO set
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Avatar alt={item.name}>
              <img alt={item.name} src={`/icons/devices/${item.type?.iconName}`} width={30} />
            </Avatar>
          </Badge>
        </ListItemAvatar>
        {!isDragging && (
          <ListItemText
            primary={
              <Grid container alignItems='center' spacing={1} component='span'>
                <Grid component='span'>
                  <Typography
                    variant='h5'
                    color='inherit'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid component='span'>{item.address}</Grid>
              </Grid>
            }
            secondary={
              <Grid container alignItems='center' component='span'>
                <Grid size={{ xs: 12 }} component='span'>
                  <Typography
                    variant='caption'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.description}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }} component='span'>
                  <Typography
                    variant='caption'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.type?.name}
                  </Typography>
                </Grid>
                {/* <Grid size={{ xs: 12 }} component='span'>
                  <Typography
                    variant='caption'
                    component='span'
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {item.location?.name}
                  </Typography>
                </Grid> */}
              </Grid>
            }
          />
        )}
      </ListItem>
      <Divider />
    </Fragment>
  );
}

// function MapItemElement({ item }: MapItemProps) {
//   return (
//     <Fragment>
//       <ListItem>
//         <ListItemAvatar title={item.device?.type?.name}>
//           <Badge
//             overlap='circular'
//             //badgeContent={<AvatarStatus status={user.online_status!} />} // TODO set
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'right',
//             }}
//           >
//             <Avatar alt={item.device?.name}>
//               <img alt={item.device?.name} src={`/icons/devices/${item.device?.type?.iconName}`} width={30} />
//             </Avatar>
//           </Badge>
//         </ListItemAvatar>
//         <ListItemText
//           primary={
//             <Grid container alignItems='center' spacing={1} component='span'>
//               <Grid component='span'>
//                 <Typography
//                   variant='h5'
//                   color='inherit'
//                   component='span'
//                   sx={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     display: 'block',
//                   }}
//                 >
//                   {item.device?.name}
//                 </Typography>
//               </Grid>
//               <Grid component='span'>{item.device?.address}</Grid>
//             </Grid>
//           }
//           secondary={
//             <Grid container alignItems='center' component='span'>
//               <Grid size={{ xs: 12 }} component='span'>
//                 <Typography
//                   variant='caption'
//                   component='span'
//                   sx={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     display: 'block',
//                   }}
//                 >
//                   {item.device?.description}
//                 </Typography>
//               </Grid>
//               <Grid size={{ xs: 12 }} component='span'>
//                 <Typography
//                   variant='caption'
//                   component='span'
//                   sx={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     display: 'block',
//                   }}
//                 >
//                   {item.device?.system?.name} | {item.device?.type?.name}
//                 </Typography>
//               </Grid>
//               <Grid size={{ xs: 12 }} component='span'>
//                 <Typography
//                   variant='caption'
//                   component='span'
//                   sx={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     display: 'block',
//                   }}
//                 >
//                   {item.device?.location?.name}
//                 </Typography>
//               </Grid>
//               <Grid size={{ xs: 12 }} component='span'>
//                 <Typography
//                   variant='caption'
//                   component='span'
//                   sx={{
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     whiteSpace: 'nowrap',
//                     display: 'block',
//                   }}
//                 >
//                   {item.name}
//                 </Typography>
//               </Grid>
//             </Grid>
//           }
//         />
//       </ListItem>
//       <Divider />
//     </Fragment>
//   );
// }

export default function DeviceList({ type }: DeviceListProps) {
  const theme = useTheme();
  const { map } = useMaps();
  const { context } = useAppContext();

  const { get, getPaged } = useAxios();

  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState<DeviceType[]>();
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);

  const [items, setItems] = useState<Device[]>();
  const [totalItemCount, setTotalItemCount] = useState<number>();

  const checkedIcon = <CheckBoxIcon fontSize='small' />;
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const renderData = (result?: PagedResult<Device[]>) => {
    if (result) {
      setItems(result.items ?? []);
      setTotalItemCount(result.pagination.totalCount);
    }
    setLoading(false);
  };

  const searchDevices = () => {
    if (type === 'Unmapped' && (value || (types && types.length > 0))) {
      setLoading(true);
      setTotalItemCount(undefined);
      const options: QueryOptions = {
        page: 0,
        pageSize: 100,
        search: value,
        filters: types && types.length > 0 ? `DeviceType=${types.map((t) => t.name).join(',')}` : '',
      };
      getPaged<Device[]>(`/api/device/${context?.systemId}/unmapped`, options).then(renderData);
    } else
      renderData({
        items: [],
        pagination: {
          totalCount: 0,
          pageSize: 0,
          currentPage: 0,
          totalPages: 0,
          hasNext: false,
          hasPrevious: false,
        },
      });
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if (event?.code !== 'Enter') {
      return;
    }
    searchDevices();
  };

  // const handleSave = async () => {
  //   const ok = await confirm(`Are you sure to upload ${unmappedItems?.length} map items?`);
  //   if (ok) {
  //     const promises: Promise<boolean>[] = [];
  //     info('Upload of map items started');
  //     unmappedItems?.forEach(async (item) => {
  //       item.device = undefined;
  //       promises.push(post<MapItem>('/api/mapitem', item));
  //     });
  //     await Promise.all(promises);
  //     success('Upload of map items finished');
  //   }
  // };

  useEffect(() => searchDevices(), [map, types]);

  useEffect(() => {
    getPaged<DeviceType[]>(`/api/devicetype/query`, { pageSize: 500, page: 0 }).then((r) => setDeviceTypes(r?.items ?? []));
  }, []);

  return (
    // <TabPanel
    //   items={[
    //     {
    //       label: 'Search Devices',
    //       index: 0,
    //       children: (
    <>
      <Autocomplete
        multiple
        fullWidth
        size='small'
        options={deviceTypes}
        value={types ?? []}
        sx={{ mb: 1, mt: 1 }}
        disabled={context?.maps?.length === 0}
        onChange={(_, o) => setTypes(o as DeviceType[])}
        // disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        getOptionKey={(option) => option.id}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => <TextField autoComplete='off' {...params} label='Device types' />}
      />
      <TextField
        size='small'
        autoComplete='off'
        fullWidth
        id='input-search-header'
        label={'Search unmapped devices'}
        placeholder='Search unmapped devices'
        value={value}
        type='new-password'
        helperText={'press enter to search'}
        variant='outlined'
        onKeyUpCapture={handleEnter}
        disabled={context?.maps?.length === 0}
        onChange={(e) => {
          setValue(e.target.value);
          // debouncedSearch(e.target.value);
        }}
        // startAdornment={
        //   <InputAdornment position='start'>
        //     <SearchTwoToneIcon fontSize='small' />
        //   </InputAdornment>
        // }
      />
      <PerfectScrollbar
        style={{
          marginTop: 2,
          overflowX: 'hidden',
          height: downLG ? 'calc(100vh - 220px)' : '40vh',
          minHeight: downLG ? 0 : 300,
        }}
      >
        <Box sx={{ p: 1, pt: 0 }}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
              <CircularProgress />
            </Box>
          )}
          {!loading &&
            items?.map((d) => (
              <Draggable key={d.id} draggableId={d.id.toString()} index={d.id}>
                {(provided, snapshot) => <DeviceItem item={d} key={d.id} dnd={provided} isDragging={snapshot.isDragging} />}
              </Draggable>
            ))}
        </Box>
      </PerfectScrollbar>
      {items && items.length > 0 && totalItemCount && totalItemCount > 0 && (
        <>
          <Divider />
          <sub style={{ marginLeft: '15px' }}>
            {items.length < totalItemCount
              ? `Showing ${items.length} devices out of ${totalItemCount.toLocaleString()}, please narrow your search.`
              : `Found ${items.length} devices.`}
          </sub>
        </>
      )}
    </>
    //       ),
    //     },
    //     {
    //       label: 'Map Devices',
    //       index: 1,
    //       children: (
    //         <>
    //           <ConfirmDialog />
    //           <Button disabled={!unmappedItems || unmappedItems.length === 0} variant='outlined' onClick={handleSave}>
    //             Save unmapped devices
    //           </Button>
    //           {!unmappedItems || ((unmappedItems?.length ?? 0) > 0 && <></>)}
    //           {unmappedItems && unmappedItems.length > 0 && (
    //             <>
    //               <PerfectScrollbar
    //                 style={{
    //                   marginTop: 2,
    //                   overflowX: 'hidden',
    //                   height: downLG ? 'calc(100vh - 220px)' : '45vh',
    //                   minHeight: downLG ? 0 : 300,
    //                 }}
    //               >
    //                 {unmappedItems.map((i, k) => (
    //                   <MapItemElement key={k} item={i} />
    //                 ))}
    //               </PerfectScrollbar>
    //               <Divider />
    //               <sub style={{ marginLeft: '15px' }}>{unmappedItems && `Found ${unmappedItems.length} devices.`}</sub>
    //             </>
    //           )}
    //         </>
    //       ),
    //     },
    //   ]}
    // />
  );
}
