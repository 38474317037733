import L, { ControlOptions } from 'leaflet';
import { MapContainer, MapContainerProps } from 'react-leaflet';

import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

interface FullscreenControlOptions extends ControlOptions {
  pseudoFullscreen?: boolean;
  title?: {
    false: string;
    true: string;
  };
}

interface LeafletMapContainerProps extends MapContainerProps {
  fullscreenControl?: true | FullscreenControlOptions | undefined;
}

interface FullscreenMap {
  isFullscreen: () => boolean;
  toggleFullscreen: (options?: FullscreenControlOptions) => void;
}

export interface BSMap extends L.Map, FullscreenMap {}

export const LeafletMapContainer = (props: LeafletMapContainerProps) => <MapContainer {...props} />;
