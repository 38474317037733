// types
import { ThemeProps, MenuOrientation, ThemeDirection, ThemeMode } from 'types/theme';

// theme constant
export const gridSpacing = 1;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// route constants
export const HOME_PATH = '/';
export const DASHBOARD_PATH = '/dashboard';
export const HORIZONTAL_MAX_ITEM = 7;

const config: ThemeProps = {
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  fontFamily: "'Roboto', sans-serif",
  borderRadius: 8,
  outlinedFilled: true,
  mode: process.env.REACT_APP_MODE?.toUpperCase() === 'LIGHT' ? ThemeMode.LIGHT : ThemeMode.DARK,
  presetColor: 'theme1',
  i18n: 'en',
  themeDirection: ThemeDirection.LTR,
  container: false,
};

export default config;
