import { useEffect, useRef, useState } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

// third-party
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import { EventDropArg, EventClickArg, DateSelectArg } from '@fullcalendar/core';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';

import { FormikValues } from 'formik';

// project imports
import Toolbar from 'components/calendar/Toolbar';
import AddEventForm from 'components/calendar/AddEventForm';
import CalendarStyled from 'components/calendar/CalendarStyled';

import CalendarContolV1 from 'components/calendar/CalendarContolV1';

import Loader from 'components/shared/Loader';
import MainCard from 'components/cards/MainCard';
import SubCard from 'components/cards/SubCard';

// assets
import AddAlarmTwoToneIcon from '@mui/icons-material/AddAlarmTwoTone';

// types
import { DateRange } from 'types';
import { AddAlarm } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// ==============================|| APPLICATION CALENDAR ||============================== //

const Calendar = () => {
  const navigate = useNavigate();
  const calendarRef = useRef<FullCalendar>(null);
  const matchSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState<boolean>(false);

  // fetch events data
  const [events, setEvents] = useState<FormikValues[]>([{}]);
  //   const calendarState = useSelector((state) => state.calendar);

  //   useEffect(() => {
  //     dispatch(getEvents()).then(() => setLoading(false));
  //   }, []);

  useEffect(() => {
    // TDOO load events
  }, []);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  // set calendar view
  useEffect(() => {
    handleViewChange(matchSm ? 'listWeek' : 'dayGridMonth');
  }, [matchSm]);

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState<DateRange | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<FormikValues | null>(null);

  // calendar event select/add/edit/delete
  const handleRangeSelect = (arg: DateSelectArg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setSelectedRange({
      start: arg.start,
      end: arg.end,
    });
    setIsModalOpen(true);
  };

  const handleEventSelect = (arg: EventClickArg) => {
    if (arg.event.id) {
      const selectEvent = events.find((_event: FormikValues) => _event.id === arg.event.id);
      setSelectedEvent(selectEvent as FormikValues[]);
    } else {
      setSelectedEvent(null);
    }
    setIsModalOpen(true);
  };

  const handleEventUpdate = async ({ event }: EventResizeDoneArg | EventDropArg) => {
    try {
      // TODO implement
      // updateEvent({
      //   eventId: event.id,
      //   update: {
      //     allDay: event.allDay,
      //     start: event.start,
      //     end: event.end,
      //   },
      // });

      setEvents([...events, event]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventCreate = async (data: FormikValues) => {
    // console.log({ id: events.length + 1, ...data });
    setEvents([...events, { id: events.length + 1, ...data }]);
    handleModalClose();
  };

  const handleUpdateEvent = async (eventId: string, update: FormikValues) => {
    setEvents([...events.filter((e) => e.id !== eventId), update]);
    handleModalClose();
  };

  const handleEventDelete = async (id: string) => {
    try {
      // TODO implement
      // removeEvent(id);
      handleModalClose();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAddClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
    setSelectedRange(null);
  };

  if (loading) return <Loader />;

  return (
    <>
      {/* <CalendarContolV1 /> */}
      <CalendarStyled>
        <Toolbar date={date} view={view} onClickNext={handleDateNext} onClickPrev={handleDatePrev} onClickToday={handleDateToday} onChangeView={handleViewChange} />
        <SubCard>
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={events}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay='block'
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleRangeSelect}
            eventDrop={handleEventUpdate}
            eventClick={handleEventSelect}
            eventResize={handleEventUpdate}
            height={matchSm ? 'auto' : 720}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </SubCard>
      </CalendarStyled>

      {/* Dialog renders its body even if not open */}
      <Dialog maxWidth='sm' fullWidth onClose={handleModalClose} open={false} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
        {isModalOpen && (
          <AddEventForm
            event={selectedEvent}
            range={selectedRange}
            onCancel={handleModalClose}
            handleDelete={handleEventDelete}
            handleCreate={handleEventCreate}
            handleUpdate={handleUpdateEvent}
          />
        )}
      </Dialog>
    </>
  );
};

export default Calendar;
