import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ContactGroup } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: ContactGroup = {
  name: '',
  id: 0,
};

type ContactGroupFormProps = {
  entity?: ContactGroup;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<ContactGroup>;
};

export const ContactGroupForm = ({ disabled, entity, onCancel, onSubmit }: ContactGroupFormProps) => {
  const methods = useForm<ContactGroup>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: ContactGroup) => onSubmit(d)} />;
};
