import {
  Dashboard as DashboardIcon,
  Sensors,
  AssuredWorkload,
  NotificationImportant,
  ManageAccounts,
  Timelapse,
  CalendarMonth,
  List,
  Devices as DeviceIcon,
  ImportExport,
  Add,
  AccessAlarm,
  DeviceHub,
  Assessment,
  MergeType,
  Settings as SettingsIcon,
  Tune,
  Person,
  CameraAlt,
  Collections,
  EmergencyShare,
  AssignmentLate,
  GroupAdd,
  PersonAdd,
  SettingsInputComponent,
  TypeSpecimen,
  PersonAddAlt1,
  SystemUpdateAltOutlined as System,
  ApartmentOutlined,
  EditLocation,
  LocationOn,
} from '@mui/icons-material';

export const AppIcons = {
  Building: ApartmentOutlined,
  Profile: Person,
  Dashboard: DashboardIcon,
  Signals: Sensors,
  Inspections: AssuredWorkload,
  Notifications: NotificationImportant,
  ManageAccounts,
  Shifts: Timelapse,
  CalendarMonth,
  List,
  Map: LocationOn,
  MapEdit: EditLocation,
  Device: SettingsInputComponent,
  ImportExport,
  Add,
  AccessAlarm,
  Assessment,
  Type: TypeSpecimen,
  SettingsIcon,
  Tune,
  Camera: CameraAlt,
  Collections,
  Alarm: EmergencyShare,
  EventLogs: AssignmentLate, // Warning,
  GroupAdd,
  PersonAddAlt1,
  System,
};

export type LabelIcon = {
  name: string;
  url: string;
};

export const LabelIcons: string[] = [
  '001-add file.png',
  '002-ebook.png',
  '003-raw file.png',
  '004-blocked.png',
  '005-chat.png',
  '006-file management.png',
  '007-missing.png',
  '008-cloud download.png',
  '009-web form.png',
  '010-download file.png',
  '011-pagination 1.png',
  '012-pagination 2.png',
  '013-upload file.png',
  '014-edit document.png',
  '015-email.png',
  '016-warning.png',
  '017-flag.png',
  '018-global.png',
  '019-favorite hearth.png',
  '020-document type definition.png',
  '021-power.png',
  '022-link file.png',
  '023-list text.png',
  '024-file protection.png',
  '025-file sharing.png',
  '026-text file.png',
  '027-audio file 1.png',
  '028-pending tasks.png',
  '029-attach file.png',
  '030-video file.png',
  '031-power meter.png',
  '032-analytics.png',
  '033-help.png',
  '034-search file.png',
  '035-document editor.png',
  '036-audio file 2.png',
  '037-favorite star.png',
  '038-remove file.png',
  '039-spreadsheet.png',
  '040-tag.png',
  '041-delete file.png',
  '042-report.png',
  '043-locked.png',
  '044-camera.png',
  '045-preview.png',
];
