import { ReactNode, createContext, useEffect, useReducer } from 'react';
import useAxios from 'hooks/useAxios';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { ActionMap } from 'types/actionMap';
import { Signal, SignalState } from 'entities';
import logger from 'utils/logger';
import useAppContext from 'hooks/useAppContext';
import { SignalStateVM, SignalVM } from 'entities/viewModels';

type SignalProviderProps = {
  children: ReactNode;
};

export enum SignalActionTypes {
  SignalUpdate = 'SIGNAL_UPDATE',
  SetSignalStates = 'SET_SIGNALSTATES',
  SignalStateUpdate = 'SIGNAL_STATE_UPDATE',
  ResetSignalStates = 'RESET_SIGNALSTATES',
}

type SignalPayload = {
  [SignalActionTypes.SignalUpdate]: Signal;
  [SignalActionTypes.SignalStateUpdate]: SignalState;
  [SignalActionTypes.SetSignalStates]: SignalStateVM[] | undefined;
  [SignalActionTypes.ResetSignalStates]: number;
};

export type SignalContextState = {
  signal?: SignalVM;
  states?: SignalStateVM[];
};

export interface SignalContextType extends SignalContextState {
  resetSignalStates: (buildingId: number) => Promise<void>;
}

type SignalActions = ActionMap<SignalPayload>[keyof ActionMap<SignalPayload>];

const initialSignalState: SignalContextState = {};

export const SignalContext = createContext<SignalContextType | null>(null);

const signalReducer = (state: SignalContextState, action: SignalActions): SignalContextState => {
  // TODO make hash map
  switch (action.type) {
    case SignalActionTypes.SignalUpdate: {
      return { ...state, signal: action.payload };
    }
    case SignalActionTypes.SignalStateUpdate: {
      return { ...state };
    }
    case SignalActionTypes.SetSignalStates: {
      return { ...state, states: action.payload };
    }
    case SignalActionTypes.ResetSignalStates: {
      return { ...state };
    }
    default: {
      return { ...state };
    }
  }
};

export const SignalProvider = ({ children }: SignalProviderProps) => {
  const { context } = useAppContext();
  const { get, post, postWithFormData } = useAxios();
  const [state, dispatch] = useReducer(signalReducer, initialSignalState);
  const { signalHandler, signalStateHandler } = BroadcastHelper();

  const setSignalStates = async (buildingId: number) => {
    var states = await get<SignalStateVM[]>(`/api/signalstate/${buildingId}/active`);
    dispatch({ type: SignalActionTypes.SetSignalStates, payload: states });
  };

  const resetSignalStates = async (buildingId: number) => {
    const success = await post(`/api/signalstate/${buildingId}/reset`);
    if (success) await setSignalStates(buildingId);
  };

  useEffect(() => {
    signalHandler((signal: Signal) => {
      dispatch({ type: SignalActionTypes.SignalUpdate, payload: signal });
    });
    signalStateHandler((signalState: SignalState) => {
      dispatch({ type: SignalActionTypes.SignalStateUpdate, payload: signalState });
    });
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        if (context?.buildingId) await setSignalStates(context?.buildingId);
      } catch (err) {
        logger.logError(`Failed to init SignalContext:`, err);
      }
    };

    init();
  }, []);

  return (
    <SignalContext.Provider
      value={{
        ...state,
        resetSignalStates,
      }}
    >
      {children}
    </SignalContext.Provider>
  );
};
