import { useContext } from 'react';
import { SignalContext } from 'contexts/SignalContext';

const useSignals = () => {
  const context = useContext(SignalContext);
  if (!context) throw new Error('context must be use inside provider');
  return context;
};

export default useSignals;
