import { Autocomplete, Input, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { SystemVM } from 'entities/viewModels';

export default function SystemsTable() {
  const columns: GridColDef<SystemVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/systems');
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 300,
    },
    {
      field: 'building',
      headerName: 'Building',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.building?.name;
      },
    },
    {
      field: 'maps',
      headerName: 'Maps',
      filterable: false,
      width: 400,
      renderCell(params) {
        const title = params.row.maps?.map((m) => m.name.replace('Concourse', 'Con'))?.join(', ') ?? '';
        return <div title={title}>{title}</div>;
      },
    },
  ];

  return <DataGrid dataUrl='/api/system/query' hideHeader={false} orderBy={'name asc'} columnDefinitions={columns} />;
}
