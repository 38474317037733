import { PaletteColor } from '@mui/material/styles';
export type FontFamily = `'Inter', sans-serif` | `'Poppins', sans-serif` | `'Roboto', sans-serif`;
export type PresetColor = 'default' | 'theme1' | 'theme2' | 'theme3' | 'theme4' | 'theme5' | 'theme6';
export type I18n = 'en' | 'fr' | 'ro' | 'zh'; // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese

export enum MenuOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ThemeDirection {
  LTR = 'ltr',
  RTL = 'rtl',
}

export type ThemeProps = {
  /**
   * the props used for menu orientation (diffrent theme layout).
   * we provide static below options -
   * 'vertical' (default) - MenuOrientation.VERTICAL
   * 'horizontal' - MenuOrientation.HORIZONTAL
   */
  menuOrientation: MenuOrientation;

  /**
   * the props used for show mini variant drawer
   * the mini variant is recommended for apps sections that need quick selection access alongside content.
   * default - false
   */
  miniDrawer: boolean;

  /**
   * The props used for the theme font-style.
   * We provide static below options -
   * `'Inter', sans-serif`
   * `'Poppins', sans-serif`
   * `'Roboto', sans-serif` (default)
   */
  fontFamily: FontFamily;

  /**
   * the props used for change globaly card border radius.
   * We provide dynamic number values for border radius -
   * default value - 8
   */
  borderRadius: number;

  /**
   * the props used for change globaly ioutlined input background color.
   * default - true which show input with background color
   * false - will show input with transparent background
   */
  outlinedFilled: boolean;

  /**
   * the props used for default theme palette mode
   * explore the default theme
   * below theme options -
   * 'light' (default) - ThemeMode.LIGHT
   * 'dark' - ThemeMode.DARK
   */
  mode: ThemeMode;

  /**
   * the props used for theme primary color variants
   * we provide static below options thoe s are already defaine in src/themes/theme -
   * 'default'
   * 'theme1'
   * 'theme2'
   * 'theme3'
   * 'theme4'
   * 'theme5'
   * 'theme6'
   */
  presetColor: PresetColor;

  /**
   * The props used for display menu-items with multi-language.
   * We provide static below languages according to 'react-intl' options - https://www.npmjs.com/package/react-intl
   * 'en' (default)
   * 'fr'
   * 'ro'
   * 'zh'
   */
  i18n: I18n;

  /**
   * the props used for default theme direction
   * explore the default theme
   * below theme options -
   * 'ltr' (default) - ThemeDirection.LTR
   * 'rtl' - ThemeDirection.RTL
   */
  themeDirection: ThemeDirection;

  /**
   * the props used for theme container.
   * the container centers your content horizontally. It's the most basic layout element.
   * true - (default) which show container
   * false - will show fluid
   */
  container: boolean;
};

export type ThemeConfigProps = {
  menuOrientation: MenuOrientation;
  miniDrawer: boolean;
  fontFamily: FontFamily;
  borderRadius: number;
  outlinedFilled: boolean;
  mode: ThemeMode;
  presetColor: PresetColor;
  i18n: I18n;
  themeDirection: ThemeDirection;
  container: boolean;
  onChangeLocale: (i18n: I18n) => void;
};

export interface CustomizationProps {
  fontFamily: FontFamily;
  borderRadius?: number;
  mode: ThemeMode;
  presetColor?: PresetColor;
  themeDirection?: ThemeDirection;
  outlinedFilled?: boolean;
}

export interface CustomShadowProps {
  z1: string;
  z8: string;
  z12: string;
  z16: string;
  z20: string;
  z24: string;
  primary: string;
  secondary: string;
  orange: string;
  success: string;
  warning: string;
  error: string;
}

export interface CustomTypography {
  customInput?: {
    marginTop: number;
    marginBottom: number;
    '& > label': {
      top: string;
      left: number;
      color?: PaletteColor | (PaletteColor | undefined)[] | PaletteColor[];
      '&[data-shrink="false"]': {
        top: string;
      };
    };
    '& > div > input': {
      padding: string;
    };
    '& legend': {
      display: string;
    };
    '& fieldset': {
      top: number;
    };
  };
  mainContent?: {
    backgroundColor?: string;
    width: string;
    minHeight: string;
    flexGrow: number;
    padding: string;
    marginTop: string;
    marginRight: string;
    borderRadius: string;
  };
  menuCaption?: {
    fontSize: string;
    fontWeight: number;
    color?: PaletteColor | (PaletteColor | undefined)[] | PaletteColor[];
    padding: string;
    textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'inherit';
    marginTop: string;
  };
  subMenuCaption?: {
    fontSize: string;
    fontWeight: number;
    color: PaletteColor | (PaletteColor | undefined)[] | PaletteColor[];
    textTransform: 'uppercase' | 'lowercase' | 'capitalize' | 'inherit';
  };
  commonAvatar?: {
    cursor: string;
    borderRadius: string;
  };
  smallAvatar?: {
    width: string;
    height: string;
    fontSize: string;
  };
  mediumAvatar?: {
    width: string;
    height: string;
    fontSize: string;
  };
  largeAvatar?: {
    width: string;
    height: string;
    fontSize: string;
  };
  heading?: string;
  textDark?: string;
  grey900?: string;
  grey800?: string;
  grey700?: string;
  grey600?: string;
  grey500?: string;
  grey400?: string;
  grey300?: string;
  grey200?: string;
  grey100?: string;
  grey50?: string;
  secondaryLight?: string;
  secondary200?: string;
  secondaryDark?: string;
  secondaryMain?: string;
  background?: string;
  darkPrimaryLight?: string;
  darkPrimaryMain?: string;
  darkPrimaryDark?: string;
  darkSecondaryMain?: string;
  darkSecondaryLight?: string;
  darkSecondary800?: string;
  darkSecondary200?: string;
  darkSecondaryDark?: string;
  secondary800?: string;
  darkPrimary800?: string;
  darkPrimary200?: string;
  darkLevel2?: string;
  darkLevel1?: string;
  darkPaper?: string;
  darkTextPrimary?: string;
  darkTextSecondary?: string;
  darkBackground?: string;
  primaryDark?: string;
  primary800?: string;
  primary200?: string;
  primaryLight?: string;
  primaryMain?: string;
  colors?: CustomTypography;
  paper?: string;
  backgroundDefault?: string;
  menuSelected?: string;
  menuSelectedBack?: string;
  divider?: string;
  customization?: CustomizationProps;
  z1?: string;
  z8?: string;
  z12?: string;
  z16?: string;
  z20?: string;
  z24?: string;
  primary?: string;
  secondary?: string;
  orange?: string;
  orangeLight?: string;
  orangeMain?: string;
  orangeDark?: string;
  successLight?: string;
  success200?: string;
  successMain?: string;
  successDark?: string;
  success?: string;
  warning?: string;
  error?: string;
  errorLight?: string;
  errorMain?: string;
  errorDark?: string;
  warningLight?: string;
  warningMain?: string;
  warningDark?: string;
}
