import { IconButton, Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import logger from 'utils/logger';
import EditIcon from '@mui/icons-material/Edit';
import useAppContext from 'hooks/useAppContext';
import { inRole } from 'utils/extensions';
import { AppRoles } from 'entities/enums/AppRoles';

export const EditTableCellAction = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>, url: string, roles?: AppRoles[]): ReactNode => {
  const { contact } = useAppContext();
  const navigate = useNavigate();

  const handleEdit = (id: number) => navigate(`${(url + '/').replace('//', '/')}${id}`);

  // TODO show view icon if readable
  if (!inRole(contact?.role, roles)) return <></>;

  //logger.log(params);
  return (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <IconButton size='small' aria-label='edit' color='inherit' onClick={() => handleEdit(params.row.id)}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
};
