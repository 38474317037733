// material-ui
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

// assets
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';

// types
import { ThemeMode } from 'types/theme';

// Link - custom style
const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === ThemeMode.DARK ? theme.palette.text.secondary : theme.palette.text.hint,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  '&:active': {
    color: theme.palette.primary.main,
  },
}));

// =============================|| LANDING - FOOTER SECTION ||============================= //

const FooterSection = () => {
  const theme = useTheme();
  const textColor = theme.palette.mode === ThemeMode.DARK ? 'text.secondary' : 'text.hint';

  return (
    <>
      <Container sx={{ mb: 15 }}>
        <Grid container spacing={6}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={8}>
              <Grid size={{ xs: 12, md: 4 }}>
                <Stack spacing={{ xs: 2, md: 5 }}>
                  <Typography variant='h4' color={textColor} sx={{ fontWeight: 500 }}>
                    About Beacon Suite
                  </Typography>
                  <Typography variant='body2' color={textColor}>
                    Monitoring signals from different pieces of equipment, systems, and infrastructure and displaying in a clear and consistent manner. To provide information and
                    status in a uniform display. This allows users and responders to clearly identify alarms and faults without being familiar with dozens of manufacturers
                    proprietary systems.
                  </Typography>
                </Stack>
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                <Grid container spacing={{ xs: 5 }}>
                  <Grid size={{ xs: 6, sm: 3 }}>
                    <Stack spacing={{ xs: 3, md: 5 }}>
                      <Typography variant='h4' color={textColor} sx={{ fontWeight: 500 }}>
                        Quick Links
                      </Typography>
                      <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                        {/* <FooterLink href="https://links.codedthemes.com/HTIBc" target="_blank" underline="none">
                                                    Blog
                                                </FooterLink> */}
                        <FooterLink href={`${window.location.origin}/`} underline='none'>
                          Documentation
                        </FooterLink>
                        <FooterLink href={`${window.location.origin}/`} underline='none'>
                          Change Log
                        </FooterLink>
                        <FooterLink href={`${window.location.origin}/`} underline='none'>
                          Support
                        </FooterLink>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 3 }}>
                    <Stack spacing={{ xs: 3, md: 5 }}>
                      <Typography variant='h4' color={textColor} sx={{ fontWeight: 500 }}>
                        About
                      </Typography>
                      <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                        <FooterLink href={`${window.location.origin}/`} underline='none'>
                          Services
                        </FooterLink>
                        <FooterLink href={`${window.location.origin}/`} underline='none'>
                          Contact Us
                        </FooterLink>
                      </Stack>
                    </Stack>
                  </Grid>
                  {/* <Grid size={{xs: 6, sm: 3}}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Beacon Suite Eco-System
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                {frameworks.map((item, index) => (
                                                    <FooterLink href={item.link} target="_blank" underline="none" key={index}>
                                                        {item.title}
                                                        {item.isUpcoming && (
                                                            <Chip variant="outlined" size="small" label="Upcoming" sx={{ ml: 0.5 }} />
                                                        )}
                                                    </FooterLink>
                                                ))}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid size={{xs: 6, sm: 3}}>
                                        <Stack spacing={{ xs: 3, md: 5 }}>
                                            <Typography variant="h4" color={textColor} sx={{ fontWeight: 500 }}>
                                                Free Versions
                                            </Typography>
                                            <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                                                <FooterLink href="https://links.codedthemes.com/Yfkxg" target="_blank" underline="none">
                                                    Free React MUI
                                                </FooterLink>
                                                <FooterLink href="https://links.codedthemes.com/epTmN" target="_blank" underline="none">
                                                    Free Bootstrap 5
                                                </FooterLink>
                                                <FooterLink href="https://links.codedthemes.com/seQKN" target="_blank" underline="none">
                                                    Free Angular
                                                </FooterLink>
                                                <FooterLink href="https://links.codedthemes.com/Wfbiy" target="_blank" underline="none">
                                                    Free Django
                                                </FooterLink>
                                            </Stack>
                                        </Stack>
                                    </Grid> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ bgcolor: 'dark.dark', py: { xs: 3, sm: 1.5 } }}>
        <Container>
          <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent='space-between' spacing={{ xs: 1.5, sm: 1, md: 3 }}>
            <Typography color='text.secondary'>Beacon Suite © YottaBits</Typography>
            {/* <Stack direction='row' alignItems='center' spacing={{ xs: 3, sm: 1.5, md: 2 }}>
              <IconButton size='small' aria-label='BeaconSuite' component={Link} href={`${window.location.origin}`}>
                <PublicIcon sx={{ color: 'text.secondary', '&:hover': { color: 'error.main' } }} />
              </IconButton>
              <IconButton size='small' aria-label='codedTheme X' component={Link} href='https://twitter.com/codedthemes' target='_blank'>
                <TwitterIcon sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }} />
              </IconButton>
              <IconButton size='small' aria-label='codedTheme Telegram' component={Link} href='https://dribbble.com/codedthemes' target='_blank'>
                <SportsBasketballIcon sx={{ color: 'text.secondary', '&:hover': { color: 'warning.main' } }} />
              </IconButton>
            </Stack> */}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default FooterSection;
