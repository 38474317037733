import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid2';
import MainCard from 'components/cards/MainCard';
import { Box } from '@mui/material';
import { ClientTitle } from 'components/shared/ClientTitle';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

const Photo = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard title={<ClientTitle prefix={'Upload photo(s) for'} />}>
          <Box>
            <Box display={'flex'} justifyContent={'right'} mb={5}>
              {/* <Dropzone /> */}
            </Box>
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Photo;
