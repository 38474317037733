import { Alarm } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useWindowSize } from 'hooks/useWindowSize';
import { SignalState } from 'entities';
import BroadcastHelper from 'helpers/BroadcastHelper';
import { useAudio } from 'hooks/useAudio';
import useNotification from 'hooks/useNotification';
import { useEffect } from 'react';

export const AlarmFab = () => {
  const { warning } = useNotification();
  const { silence, active, play } = useAudio();
  const { width } = useWindowSize();
  const { signalStateHandler } = BroadcastHelper();

  useEffect(() => {
    signalStateHandler((signalState: SignalState) => {
      // logger.log(`Signal State raised with sound: ${signalState.soundAlarm}`);
      if (signalState.soundAlarm) play(); //random(0, 50) === 13);
    });
  }, []);

  if (!active) return <></>;
  return (
    // <Zoom
    //   key={fab.color}
    //   in={value === index}
    //   timeout={transitionDuration}
    //   style={{
    //     transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
    //   }}
    //   unmountOnExit
    // >
    <Fab
      sx={
        width!! < 450
          ? {
              position: 'fixed',
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }
          : {}
      }
      size='medium'
      color='error'
      aria-label='silence'
      onClick={() => {
        silence();
        warning(`Alarm silenced`);
      }}
    >
      <Alarm />
    </Fab>
    // </Zoom>
  );
};
