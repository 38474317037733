import { Link } from 'react-router-dom';

// material-ui
import { Theme } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import ResetPasswordFrom from '../../forms/ResetPasswordFrom';
import { AuthWrapper } from './AuthWrapper';

const ResetPassword = () => {
  const downMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <AuthWrapper>
      <Grid size={{ xs: 12 }}>
        <Grid container direction={{ xs: 'column-reverse', md: 'row' }} alignItems='center' justifyContent='center'>
          <Grid>
            <Stack alignItems='center' justifyContent='center' spacing={1}>
              <Typography color='secondary.main' gutterBottom variant={downMD ? 'h3' : 'h2'}>
                Reset Password
              </Typography>
              <Typography variant='caption' fontSize='16px' textAlign={{ xs: 'center', md: 'inherit' }}>
                Please choose your new password
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ResetPasswordFrom />
      </Grid>
    </AuthWrapper>
  );
};

export default ResetPassword;
