import { useCallback, useEffect, useState } from 'react';
import {
  DataGrid as DataGridInstance,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import logger from '../../utils/logger';
import { Box, IconButton, useTheme } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { Entity } from '../../entities/base/Entity';
import { Pagination } from '../../entities/base/Pagination';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import useAxios, { PagedResult, QueryOptions } from 'hooks/useAxios';
import { GridApiCommunity } from '@mui/x-data-grid/internals';

//TODO spilt types for server and rows rendering
type DataGridProps = {
  rows?: Entity[];
  dataUrl?: string;
  orderBy?: string;
  reload?: boolean;
  isLoading?: boolean;
  columnDefinitions: GridColDef[];
  hideHeader?: boolean;
  hideFooter?: boolean;
  pageSizeOptions?: number[];
  api?: React.MutableRefObject<GridApiCommunity>;
  customToolbar?: () => JSX.Element;
  onRowClick?: (entityId: number) => void;
};

const InitialModelState: GridInitialStateCommunity = {
  // filter: {
  //   filterModel: {
  //     items: rows,
  //     quickFilterValues: ['quick', 'filter'],
  //   },
  // },
  pagination: {
    paginationModel: {
      page: 0,
      pageSize: 10,
    },
  },
  sorting: {
    sortModel: [
      {
        field: 'id',
        sort: 'desc',
      },
    ],
  },
};

//#region TODO set custon pagination
// interface PaginationActionsProps {
//   count: number;
//   page: number;
//   rowsPerPage: number;
//   onPageChange: (
//     event: React.MouseEvent<HTMLButtonElement>,
//     newPage: number
//   ) => void;
// }

// function CustomPaginationToolbar(props: PaginationActionsProps) {
//   const theme = useTheme();
//   const { count, page, rowsPerPage, onPageChange } = props;

//   const handleFirstPageButtonClick = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     onPageChange(event, 0);
//   };

//   const handleBackButtonClick = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     onPageChange(event, page - 1);
//   };

//   const handleNextButtonClick = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     onPageChange(event, page + 1);
//   };

//   const handleLastPageButtonClick = (
//     event: React.MouseEvent<HTMLButtonElement>
//   ) => {
//     onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   return (
//     <Box component="div" sx={{ flexShrink: 0, ml: 2.5 }}>
//       <IconButton
//         onClick={handleFirstPageButtonClick}
//         disabled={page === 0}
//         aria-label="first page"
//       >
//         {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//       </IconButton>
//       <IconButton
//         onClick={handleBackButtonClick}
//         disabled={page === 0}
//         aria-label="previous page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowRight />
//         ) : (
//           <KeyboardArrowLeft />
//         )}
//       </IconButton>
//       <IconButton
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowLeft />
//         ) : (
//           <KeyboardArrowRight />
//         )}
//       </IconButton>
//       <IconButton
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//       </IconButton>
//     </Box>
//   );
// }
//#endregion

function HeaderToolbar() {
  return (
    <GridToolbarContainer sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      {/* <GridToolbarExport printOptions={{ disableToolbarButton: true }} /> */}
      <GridToolbarQuickFilter debounceMs={1000} />
    </GridToolbarContainer>
  );
}

export const DataGrid = (props: DataGridProps) => {
  const { getPaged } = useAxios();

  const [rows, setRows] = useState<Entity[]>([]);
  const [dataUrl, setDataUrl] = useState(props.dataUrl);

  const [loading, setLoading] = useState(props.isLoading ?? true);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: props?.orderBy?.split(' ')[0] ?? 'name',
      sort: (props?.orderBy?.split(' ')[1] as GridSortDirection) ?? 'asc',
    },
  ]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });

  const [model, setModel] = useState<QueryOptions>({
    page: 0,
    pageSize: 10,
    orderBy: props.orderBy ?? 'name asc',
    search: '',
  });

  const [rowCount, setRowCount] = useState(0);
  //const [pagination, setPagination] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })
  const [rowCountState, setRowCountState] = useState(rowCount);

  const handlePagination = (paginationModel: GridPaginationModel) => {
    setLoading(true);
    setPaginationModel(paginationModel);
    setModel({ ...model, ...paginationModel });
  };

  const handleSorting = (sortModel: GridSortModel) => {
    setLoading(true);
    setSortModel(sortModel);
    const orderBy = sortModel.map((m) => `${m.field} ${m.sort}`).join(', ');
    setModel({ ...model, orderBy: orderBy });
  };

  const handleFilters = useCallback((filterModel: GridFilterModel) => {
    setLoading(true);
    const search = filterModel.quickFilterValues?.at(0);
    setModel({ ...model, ...InitialModelState.pagination, search: search });
  }, []);

  const renderData = (result?: PagedResult<Entity[]>) => {
    if (result) {
      //const pagination: Pagination = JSON.parse(response.headers['x-pagination']);
      setPaginationModel({ page: result.pagination.currentPage, pageSize: result.pagination.pageSize });
      //logger.log('renderData:', response);
      setRowCount(result.pagination.totalCount);
      setRows(result.items ?? []); ///response.data as EntityID[]);
    } else setRows([]);
    setLoading(false);
  };

  const fetchData = (options?: QueryOptions) => {
    setLoading(true);
    if (dataUrl) {
      // setRows([]);
      getPaged<Entity[]>(dataUrl, options).then(renderData);
    } else {
      setRows([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    fetchData(model);
  }, [model]); //, invalidateData]);

  useEffect(() => {
    setLoading(true);
    // load data from rows
    if (props.rows) {
      setRowCount(props.rows.length);
      setRows(props.rows);
      setLoading(false);
    }
  }, [props.rows]);

  useEffect(() => {
    fetchData({
      page: 0,
      pageSize: 10,
      orderBy: props.orderBy ?? 'name asc',
      search: '',
    });
  }, [props.reload, dataUrl]);

  useEffect(() => {
    if (props.dataUrl !== dataUrl) setDataUrl(props.dataUrl);
  }, [props.dataUrl]);

  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiDataGrid-root': {
          '& .MuiDataGrid-toolbarContainer': {
            pl: 3,
            pr: 2,
            pt: 2,
            '& .MuiButton-root': {
              p: 1,
              color: 'common.white',
              borderRadius: 1.5,
              bgcolor: 'primary.main',
            },
            '& .MuiFormControl-root > .MuiInput-root': {
              p: 0.6,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 2,
              bgcolor: 'grey.50',
            },
            '& .MuiFormControl-root > .MuiInputBase-root:after': {
              display: 'none',
            },
            '& .MuiFormControl-root > .MuiInputBase-root:before': {
              display: 'none',
            },
            '& .MuiFormControl-root > .Mui-focused': {
              border: '1px solid',
              borderColor: 'primary.main',
            },
          },
        },
      }}
    >
      <DataGridInstance
        rows={rows}
        apiRef={props.api}
        loading={loading}
        autoHeight={true}
        rowCount={dataUrl ? rowCountState : undefined}
        disableRowSelectionOnClick={true}
        columns={props.columnDefinitions}
        initialState={InitialModelState}
        filterMode={dataUrl ? 'server' : 'client'}
        pageSizeOptions={props.pageSizeOptions ?? [10, 50, 100]}
        paginationMode={dataUrl ? 'server' : 'client'}
        sortModel={sortModel}
        paginationModel={paginationModel}
        hideFooterPagination={rowCountState < paginationModel.pageSize}
        onPaginationModelChange={handlePagination}
        onSortModelChange={handleSorting}
        onFilterModelChange={handleFilters}
        onRowClick={(e) => (props.onRowClick ? props.onRowClick(e.row.id) : null)}
        slots={{
          //pagination: PaginationActions, //TODO https://codesandbox.io/s/datagrid-v5-quick-start-forked-cirxwf?file=/src/App.tsx
          toolbar: props.customToolbar ?? ((props?.hideHeader ?? true) ? undefined : HeaderToolbar),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: props?.hideHeader ?? true,
            printOptions: { disableToolbarButton: true },
          },
        }}
        hideFooter={props?.hideFooter ?? false}
        //sx={{ marginBottom: '50px' }}
        //TODO alternate rows not working
        //getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'mui-even' : 'mui-odd')}
      />
    </Box>
  );
};
