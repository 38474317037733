import { useState, useEffect } from 'react';
import { Photo } from 'entities';
import useAxios from 'hooks/useAxios';
import useAppContext from 'hooks/useAppContext';

export default function PhotosGallery() {
  const { get } = useAxios();
  const { context } = useAppContext();
  const [index, setIndex] = useState(-1);
  const [photos, setPhotos] = useState<Photo[]>([
    {
      src: 'photos/photo_2023-09-27_13-07-03.jpg',
      name: '',
      id: 1,
      width: 1280,
      height: 960,
    },
    {
      src: 'photos/photo_2023-09-27_13-07-57.jpg',
      name: '',
      id: 2,
      width: 1280,
      height: 960,
    },
    {
      src: 'photos/photo_2024-01-08_13-45-56.jpg',
      name: '',
      id: 3,
      width: 1280,
      height: 960,
    },
    {
      src: 'photos/photo_2024-05-07_17-02-16.jpg',
      name: '',
      id: 4,
      width: 598,
      height: 1280,
    },
    {
      src: 'photos/photo_2024-05-15_21-53-33.jpg',
      name: '',
      id: 5,
      width: 1280,
      height: 598,
    },
    {
      src: 'photos/photo_2024-05-16_21-21-53.jpg',
      name: '',
      id: 6,
      width: 598,
      height: 1280,
    },
    {
      src: 'photos/photo_2024-06-11_12-31-45.jpg',
      name: '',
      id: 0,
      width: 1280,
      height: 598,
    },
  ]);

  useEffect(() => {
    // if (building) get<Photo[]>(`/api/photo/all/${building.id}`).then(setPhotos);
  }, []);

  return <></>;
}
