import useAppContext from 'hooks/useAppContext';

type SiteNameProps = {
  prefix?: string;
  suffix?: string;
  showClient?: boolean;
  showSite?: boolean;
  showBuilding?: boolean;
  showSiteCode?: boolean;
  showSystem?: boolean;
  showBuildingShortName?: boolean;
};

export const ClientTitle = ({ prefix, suffix, showClient, showSite, showBuilding, showSiteCode, showSystem, showBuildingShortName }: SiteNameProps) => {
  const { context } = useAppContext();

  if (showClient && showBuilding && showSiteCode)
    return <>{`${prefix ?? ''} ${[context?.clientName, context?.siteName, context?.buildingName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuilding && showSiteCode) return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuildingShortName && showSiteCode && showSystem)
    return <>{`${prefix ?? ''} ${[context?.siteCode, context?.buildingShortName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showBuildingShortName && showSystem) return <>{`${prefix ?? ''} ${[context?.buildingShortName, context?.systemName].join(' - ')} ${suffix ?? ''}`.trim()}</>;

  if (showClient) return <>{`${prefix ?? ''} ${context?.clientName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showBuilding) return <>{`${prefix ?? ''} ${context?.buildingName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showSite) return <>{`${prefix ?? ''} ${context?.siteName ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showSiteCode) return <>{`${prefix ?? ''} ${context?.siteCode ?? ''} ${suffix ?? ''}`.trim()}</>;
  if (showBuildingShortName) return <>{`${prefix ?? ''} ${context?.buildingShortName ?? ''} ${suffix ?? ''}`.trim()}</>;

  if (prefix) return `${prefix} ${context?.buildingName}`;

  return <>{`${context?.siteCode} - ${showBuildingShortName ? context?.buildingShortName : context?.buildingName} - ${context?.systemName}`.trim()}</>;
};
