import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { System, InspectionType } from 'entities';
import { IntervalType } from 'entities/enums/IntervalType';
import { ReactNode } from 'react';

export default function InspectionTypesTable() {
  const columns: GridColDef<InspectionType>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/types/inspection/');
      },
    },
    { field: 'name', headerName: 'Name', width: 400, filterable: false },
    {
      field: 'groups',
      headerName: 'System(s)',
      width: 400,
      filterable: false,
      renderCell(params): ReactNode {
        return 'TODO ENRICH';
        // const systems = params.row.systems as System[];
        // return <>{systems?.map((g) => g.name).join(', ') ?? '-'}</>;
      },
    },
    {
      field: 'interval',
      headerName: 'Interval',
      width: 200,
      filterable: false,
      renderCell(params): ReactNode {
        return <>{params.row.intervalValue && params.row.intervalType ? `every ${params.row.intervalValue} ${IntervalType[params.row.intervalType]}` : 'N/A'}</>;
      },
    },
  ];

  return <DataGrid dataUrl={'/api/inspectionType/query'} columnDefinitions={columns} />;
}
