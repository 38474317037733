import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { ReactNode } from 'react';

type ContextDialogProps = {
  children: ReactNode;
  open: boolean;
  actions: ReactNode;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const ContextDialog = ({ children, open, actions, onClose, onSubmit }: ContextDialogProps) => {
  const stopPropagation = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      event.stopPropagation();
    }
  };

  return (
    <Dialog
      onKeyDown={stopPropagation}
      open={open}
      onClose={onClose}
      PaperProps={{
        component: 'form',
        onSubmit: onSubmit,
      }}
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
