import { AddLocation } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, FormControl, TextField, Divider, Avatar } from '@mui/material';
import { Device } from 'entities';
import { MapItem } from 'entities';
import useAxios from 'hooks/useAxios';
import { LatLng } from 'leaflet';
import { useState, useEffect } from 'react';
import { MenuItemProps } from '../ContextMenu';
import useAppContext from 'hooks/useAppContext';
import { LabelIcons } from 'types/icons';
import { ContextDialog } from '../ContextDialog';
import { MapLabel } from 'entities/MapLabel';
import useNotification from 'hooks/useNotification';
import useMaps from 'hooks/useMaps';

interface AddLabelProps extends MenuItemProps {
  position?: LatLng;
}

export const AddLabel = ({ position, disabled, onClick }: AddLabelProps) => {
  const { get, post } = useAxios();
  const { map, refreshMap } = useMaps();
  const { success } = useNotification();

  const [icons, setIcons] = useState<string[]>(LabelIcons.map((i) => `/icons/labels/${i}`));

  // dialog props
  const [dialogOpen, setDialogOpen] = useState(false);

  // autocomplete props
  const [icon, setIcon] = useState('');
  const [value, setValue] = useState('');
  const [description, setDescription] = useState('');

  const handleDialogClose = () => {
    setDialogOpen(false);
    onClick();
  };

  //   const handleAddMarker = (position?: LatLng) => {
  //     if (position) {
  //       setPosition(position);
  //       setDialogOpen(true);
  //     }
  //   };

  const handleDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (map && position && icon.length > 0 && value.length > 0) {
      const mapId = map.id;
      const label: MapLabel = {
        id: 0,
        iconName: icon.split('/').reverse()[0],
        name: value,
        description: description,
      };
      // TODO add label instead of item
      const mapItem: MapItem = { id: 0, lat: position.lat, lng: position.lng, mapID: mapId, name: 'Label item' };
      post(`/api/mapItem/`, mapItem).then(() => {
        refreshMap();
        handleDialogClose();
        onClick();
        success(`Successfully added label '${value}'`);
        // setPosition(undefined);
        // setContextMenu(null);
      });
    }
  };

  return (
    <>
      <MenuItem onClick={() => setDialogOpen(true)} disabled={disabled}>
        <ListItemIcon>
          <AddLocation fontSize='small' />
        </ListItemIcon>
        <ListItemText>Add Label</ListItemText>
      </MenuItem>
      <ContextDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        actions={
          <>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button type='submit' disabled={!value || !icon}>
              Save
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
            <Avatar alt='Label icon' sx={{ width: 50, height: 50 }}>
              {icon ? <img src={icon} style={{ width: 'auto', height: 40 }} /> : '?'}
            </Avatar>
            <Autocomplete
              id='country-select-demo'
              sx={{ width: 250 }}
              options={icons}
              value={icon}
              onChange={(_, v) => setIcon(v ?? '')}
              autoHighlight
              getOptionLabel={(option) => option.split('-').reverse()[0].replace('.png', '')}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box key={key} component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                    <img style={{ backgroundColor: 'white' }} width='50' src={option} alt='' />
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  autoComplete='off'
                  {...params}
                  label='Choose a icon'
                  // slotProps={{
                  //   htmlInput: {
                  //     ...params.inputProps,
                  //     autoComplete: 'new-password', // disable autocomplete and autofill
                  //   },
                  // }}
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ mt: 2, minWidth: 300 }}>
            <TextField
              autoComplete='off'
              autoFocus
              required
              value={value}
              onChange={(e) => setValue(e.target.value)}
              label='Title'
              InputProps={{
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          </FormControl>

          <FormControl sx={{ mt: 2, minWidth: 300 }}>
            <TextField
              autoComplete='off'
              multiline
              minRows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label='Description'
              InputProps={{
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          </FormControl>
        </Box>
      </ContextDialog>
    </>
  );
};
