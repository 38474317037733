import { useCallback, useState, useMemo, useEffect, useRef, useContext } from 'react';
import { useEventHandlers } from '@react-leaflet/core';
import { MapContainer, Rectangle, WMSTileLayer, useMap, useMapEvent } from 'react-leaflet';
import { CRS, Map, PathOptions, TileLayer } from 'leaflet';
import useMaps from 'hooks/useMaps';
import { MINZOOM } from '../MapContainer';

const BOUNDS_STYLE: PathOptions = { weight: 1 };

type MinimapBoundsProps = {
  parentMap: Map;
  zoom?: number;
};

type MinimapControlProps = {
  zoom?: number;
  width: number;
  height: number;
};

function MinimapBounds({ parentMap, zoom }: MinimapBoundsProps) {
  const minimap = useMap();

  const onClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent('click', onClick);

  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    if (zoom ?? 0 <= MINZOOM) return;
    setBounds(parentMap.getBounds());
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), []);
  // @ts-ignore TODO fix if needed
  useEventHandlers({ instance: parentMap }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
}

export default function MinimapControl({ zoom, width, height }: MinimapControlProps) {
  const { map } = useMaps();
  const parentMap = useMap();

  const mapZoom = zoom || 0;

  const tileOverlayRef = useRef<TileLayer.WMS>(null);

  useEffect(() => {
    if (tileOverlayRef.current && map) {
      tileOverlayRef.current.setUrl(map.url);
    }
  }, [map]);

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        crs={CRS.Simple}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        zoomControl={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        className='map-container'
        style={{ height: height, width: width }}
      >
        <WMSTileLayer
          format='image/png'
          ref={tileOverlayRef}
          url={map?.url ?? ''}
          // crossOrigin={true}
          accessToken={localStorage.getItem('bs-token') ?? undefined}
        />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    []
  );

  return (
    <div className={'leaflet-bottom leaflet-right'}>
      <div className='leaflet-control leaflet-bar'>{minimap}</div>
    </div>
  );
}
