import { NamedEntity } from './base/Entity';
import { ShiftQuestionResult } from './ShiftQuestionResult';

export enum ShiftType {
  First,
  Second,
  Third,
  Weekend,
}

export interface Shift extends NamedEntity {
  date: string;
  type?: ShiftType;
  activityReport: string;
  openConcernsAndOrViolations: string;

  managerID: number;
  additionalPersonnelID: number;
  relievedByID: number;
  submittedById?: string;

  answers?: ShiftQuestionResult[];
}
