import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CalendarItem } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: CalendarItem = {
  startDate: '',
  endDate: '',
  allDay: false,
  area: '',
  workType: '',
  systemsEffected: '',
  requiredFireWatch: false,
  requiredSystemImpairment: false,
  contractor: '',
  orderNumber: '',
  name: '',
  id: 0,
};

type CalendarFormProps = {
  entity?: CalendarItem;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<CalendarItem>;
};

export const CalendarForm = ({ disabled, entity, onCancel, onSubmit }: CalendarFormProps) => {
  const methods = useForm<CalendarItem>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
      span: 12,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: CalendarItem) => onSubmit(d)} />;
};
