import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid2';
import MainCard from 'components/cards/MainCard';
import { Box, Button } from '@mui/material';
import { ClientTitle } from 'components/shared/ClientTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TableChartIcon from '@mui/icons-material/TableChart';
import CollectionsIcon from '@mui/icons-material/Collections';
import MapIcon from '@mui/icons-material/Map';
import PhotosGallery from 'collections/PhotosGallery';
import PhotosTable from 'collections/PhotosTable';
import { useNavigate } from 'react-router-dom';
import { Toggler } from 'components/shared/Toggler';

const Photos = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [component, setComponent] = useState<string>('gallery');

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard
          title={<ClientTitle prefix={'Photos for'} />}
          secondary={
            <Button variant='contained' onClick={() => navigate('/photo')} startIcon={<CloudUploadIcon />}>
              {'Upload'}
            </Button>
          }
        >
          <Box>
            <Box display={'flex'} justifyContent={'right'} mb={5}>
              <Toggler
                id='photo'
                value={component}
                values={[
                  { name: 'table', icon: <TableChartIcon />, disabled: true },
                  { name: 'gallery', icon: <CollectionsIcon /> },
                  { name: 'mape', icon: <MapIcon />, disabled: true },
                ]}
                onChange={setComponent}
              />
            </Box>
            {component === 'table' && <PhotosTable />}
            {component === 'gallery' && <PhotosGallery />}
            {component === 'map' && <>MAP</>}
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Photos;
