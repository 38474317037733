import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Device } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';
const InitialState: Device = {
  name: '',
  id: 0,
};

type DeviceFormProps = {
  entity?: Device;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Device>;
};

export const DeviceForm = ({ disabled, entity, onCancel, onSubmit }: DeviceFormProps) => {
  const { context } = useAppContext();

  const methods = useForm<Device>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'System',
      name: 'groupID',
      nonLazy: true,
      type: FieldType.LOOKUP,
      dataUrl: '/api/system/query',
      control: methods.control,
      description: 'Setting this field will rebind all signals belonging to this address.',
    },
    {
      label: 'Category',
      name: 'category',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/categories',
      control: methods.control,
    },
    {
      label: 'Description',
      name: 'description',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Label',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Address',
      name: 'address',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Barcode',
      name: 'barcode',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Type',
      name: 'typeID',
      nonLazy: true,
      type: FieldType.LOOKUP,
      dataUrl: '/api/deviceType/query',
      control: methods.control,
    },
    {
      label: 'Type Code',
      name: 'typeCode',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/deviceTypeCodes',
      control: methods.control,
    },
    {
      label: 'Hardware Type',
      name: 'hardwareType',
      type: FieldType.AUTOCOMPLETE,
      dataUrl: '/api/hardwareTypes',
      control: methods.control,
    },

    {
      label: 'Serial number',
      name: 'serialNumber',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'manufacturer',
      name: 'manufacturer',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Part number',
      name: 'partNumber',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Curcuit',
      name: 'curcuit',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Circuit Type',
      name: 'circuitType',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Area',
      name: 'area',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Section',
      name: 'section',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Floor',
      name: 'floor',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Room',
      name: 'room',
      type: FieldType.TEXT,
      control: methods.control,
    },

    {
      label: 'Directions',
      name: 'directions',
      type: FieldType.TEXT,
      control: methods.control,
    },

    {
      label: 'Notes',
      name: 'notes',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Parent Device',
      name: 'parentDeviceID',
      type: FieldType.LOOKUP,
      dataUrl: `/api/device/${context?.systemId}/query`,
      control: methods.control,
    },
    { label: 'Exclude from ...', name: 'exclude', type: FieldType.CHECKBOX, disabled: true, control: methods.control },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Device) => onSubmit(d)} />;
};
