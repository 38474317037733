import { CircularProgress, IconButton, Stack } from '@mui/material';
import { InspectionResult } from 'entities';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import { InspectionResultType } from 'views/Inspection';
import { Sensors, SensorsOff, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';

type InspectionResultActionProps = {
  type: InspectionResultType;
  item: InspectionResult;
  onPass: (item: InspectionResult) => Promise<void>;
  onFail: (item: InspectionResult, type: InspectionResultType) => Promise<void>;
};

export const InspectionResultAction = ({ type, item, onPass, onFail }: InspectionResultActionProps) => {
  const [disabled, setDisabled] = useState(false);
  const outcome = type === 'Functional' ? item.outcomeFunctional : item.outcomeVisibility;

  if (item && outcome !== null && outcome !== undefined) {
    const passed = outcome === InspectionOutcome.PASS;
    // console.log(`${type}[${item.id}]: ${outcome}=${passed}`);
    return (
      <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
        <IconButton
          color={passed ? 'success' : 'error'}
          sx={{ width: 25, height: 25, ':hover': { cursor: 'default', backgroundColor: 'transparent' } }}
          title={`${type} check ${passed ? 'passed' : 'failed'} ${(type === 'Functional' ? item.commentFunctional : item.commentVisibility) ?? ''}`}
        >
          {type === 'Functional' ? (
            <Sensors sx={{ width: 20, height: 20, fill: passed ? '#6cc067' : '#d9534' }} />
          ) : (
            <Visibility sx={{ width: 20, height: 20, fill: passed ? '#6cc067' : '#d9534' }} />
          )}
        </IconButton>
      </Stack>
    );
  }

  const handlePassed = () => {
    setDisabled(true);
    type === 'Functional'
      ? onPass({ ...item!!, outcomeFunctional: InspectionOutcome.PASS, commentFunctional: '' }).then(() => setDisabled(false))
      : onPass({ ...item!!, outcomeVisibility: InspectionOutcome.PASS, commentVisibility: '' }).then(() => setDisabled(false));
  };

  const handleFail = () => {
    setDisabled(true);
    onFail(item, type).then(() => setDisabled(false));
  };

  return disabled ? (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <CircularProgress size={20} />
    </Stack>
  ) : (
    <Stack direction='row' spacing={1} alignItems='center' height={'100%'}>
      <IconButton disabled={disabled} color='success' sx={{ width: 25, height: 25 }} title={`${type} check passed?`} onClick={() => handlePassed()}>
        {type === 'Functional' ? <Sensors sx={{ width: 20, height: 20 }} /> : <Visibility sx={{ width: 20, height: 20 }} />}
      </IconButton>
      <IconButton disabled={disabled} color='error' sx={{ width: 25, height: 25 }} title={`${type} check failed?`} onClick={handleFail}>
        {type === 'Functional' ? <SensorsOff sx={{ width: 20, height: 20 }} /> : <VisibilityOff sx={{ width: 20, height: 20 }} />}
      </IconButton>
    </Stack>
  );
};
