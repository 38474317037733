import { useEffect, useState } from 'react';
import { gridSpacing } from 'config';
import Grid from '@mui/material/Grid2';
import MainCard from 'components/cards/MainCard';
import InspectionsTable from 'collections/InspectionsTable';
import { Button } from '@mui/material';
import { ClientTitle } from 'components/shared/ClientTitle';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export const InspectionsView = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid size={{ xs: 12 }}>
        <MainCard
          title={<ClientTitle prefix={'Inspections for'} showBuildingShortName showSystem />}
          secondary={
            <Button variant='outlined' startIcon={<AddIcon />} onClick={() => navigate('/inspections/0')}>
              Add Inspection
            </Button>
          }
        >
          <InspectionsTable />
        </MainCard>
      </Grid>
    </Grid>
  );
};
