import { ReactNode, useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender, useGridApiRef } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Box } from '@mui/material';
import { DataGrid } from 'components/dataGrid/DataGrid';
import useAppContext from 'hooks/useAppContext';
import useSignals from 'hooks/useSignals';
import { SignalDeviceTableCellAction } from 'components/actions/SignalDeviceTableCellAction';
import { Signal } from 'entities';
import { SignalVM } from 'entities/viewModels';

type SignalsTableProps = {
  showActions?: boolean;
  activeOnly: boolean;
  dataUrl?: string;
  pageSizeOptions?: number[];
  customColumns?: GridColDef<SignalVM>[];
};

export default function SignalsTable({ activeOnly, dataUrl, pageSizeOptions, showActions, customColumns }: SignalsTableProps) {
  const { context, contact } = useAppContext();
  const apiRef = useGridApiRef();
  const { states, signal } = useSignals();

  const [reload, setReload] = useState(false);

  const renderTypeCell = (params: GridRenderCellParams<SignalVM, any, any, GridTreeNodeWithRender>): ReactNode => {
    return (
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginRight: '10px',
        }}
      >
        {params.row.type?.name}
      </Box>
    );
  };

  const actionColumns: GridColDef<SignalVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return SignalDeviceTableCellAction({ item: params.row });
      },
      // renderCell: SignalDeviceTableCellAction,
    },
  ];

  const columns: GridColDef<SignalVM>[] = [
    ...(showActions ? actionColumns : []),
    ...(customColumns ?? []),
    {
      field: 'signalType',
      headerName: 'Type',
      filterable: true,
      width: 175,
      renderCell: renderTypeCell,
    },
    {
      field: 'message',
      headerName: 'Signal',
      width: 300,
      filterable: false,
      renderCell(params): ReactNode {
        var message = params.value as string;
        return <div title={message}>{message}</div>;
      },
    },
    {
      field: 'device.Address',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row.deviceID ? (
          <Link component={RouterLink} to={`/devices/${params.row.deviceID}`} sx={{ textDecoration: 'underline' }}>
            {params.row.device?.address ?? ''}
          </Link>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'device.Description',
      headerName: 'Description',
      width: 300,
      filterable: false,
      renderCell(params) {
        const value = params.row.device?.description ?? '';
        return <div title={value}>{value}</div>;
      },
    },
    {
      field: 'system',
      headerName: 'System',
      filterable: true,
      width: 200,
      renderCell(params) {
        return params.value?.name ?? contact?.systems?.find((s) => s.id === params.row.device?.systemID)?.name ?? '';
      },
    },
  ];

  // useEffect(() => {
  //   setShowNotification(false);
  // }, [reloadData]);

  useEffect(() => {
    setReload(!reload);
  }, [activeOnly, states, signal]);

  return (
    <DataGrid
      api={apiRef}
      reload={reload}
      hideHeader={false}
      pageSizeOptions={pageSizeOptions}
      // rows={states.length > 0 ? states.map((s) => s.signalActivated) : []}
      dataUrl={dataUrl ?? (activeOnly ? `/api/signal/query/${context?.buildingId}/active` : `/api/signal/query/${context?.buildingId}/all`)}
      orderBy={'date desc'}
      columnDefinitions={columns}
    />
  );
}
