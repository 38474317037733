import { useState, useEffect } from 'react';
import { Grid2 as Grid, TextField, MenuItem } from '@mui/material';
import { gridSpacing } from 'config';
import useAppContext from 'hooks/useAppContext';

export const SystemSelector = () => {
  const { contact, context, clients, sites, buildings, setContext } = useAppContext();

  const [_clientId, setClientId] = useState<number>();
  const [_siteId, setSiteId] = useState<number>();
  const [_buildingId, setBuildingId] = useState<number>();
  const [_systemId, setSystemId] = useState<number>();

  useEffect(() => {
    if (_systemId) setContext(_systemId);
  }, [_systemId]);

  useEffect(() => {
    if (context) {
      setClientId(context.clientId);
      setSiteId(context.siteId);
      setBuildingId(context.buildingId);
      setSystemId(context.systemId);
    }
  }, [context]);

  return (
    <Grid spacing={gridSpacing} container>
      <Grid size={{ xs: 12, md: 3 }}>
        {_clientId && clients && (
          <TextField autoComplete='off' select fullWidth value={_clientId} label={'Client'} onChange={(e) => setClientId(parseInt(e.target.value))}>
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        {_clientId && sites && (
          <TextField
            autoComplete='off'
            select
            fullWidth
            value={_siteId}
            label={'Site'}
            onChange={(e) => setSiteId(parseInt(e.target.value))}
            // SelectProps={{
            //   native: true,
            // }}
          >
            {sites
              ?.filter((site) => site.clientID === _clientId)
              ?.map((site) => (
                <MenuItem key={site.id} value={site.id}>
                  {site.name}
                </MenuItem>
              )) ?? <MenuItem>{''}</MenuItem>}
          </TextField>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        {_clientId && _siteId && buildings && (
          <TextField
            autoComplete='off'
            select
            fullWidth
            value={_buildingId}
            label={'Building'}
            onChange={(e) => setBuildingId(parseInt(e.target.value))}
            // SelectProps={{
            //   native: true,
            // }}
          >
            {(_clientId &&
              _siteId &&
              buildings
                ?.filter((building) => building.siteID === _siteId)
                ?.map((building) => (
                  <MenuItem key={building.id} value={building.id}>
                    {building.name}
                  </MenuItem>
                ))) ?? <MenuItem>{''}</MenuItem>}
          </TextField>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        {_clientId && _siteId && _buildingId && contact?.systems && (
          <TextField
            autoComplete='off'
            select
            fullWidth
            value={_systemId}
            label={'System'}
            onChange={(e) => setSystemId(parseInt(e.target.value))}
            // SelectProps={{
            //   native: true,
            // }}
          >
            {(_clientId &&
              _siteId &&
              _buildingId &&
              contact?.systems
                ?.filter((system) => system.buildingID === _buildingId)
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((system) => (
                  <MenuItem key={system.id} value={system.id}>
                    {system.name}
                  </MenuItem>
                ))) ?? <MenuItem>{''}</MenuItem>}
          </TextField>
        )}
      </Grid>
    </Grid>
  );
};
