import { createContext, ReactNode } from 'react';

// project import
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// types
import { ThemeConfigProps, I18n } from 'types/theme';

// initial state
const initialState: ThemeConfigProps = {
  ...defaultConfig,
  onChangeLocale: () => {},
};

// ==============================|| THEME CONTEXT & PROVIDER ||============================== //

const ThemeContext = createContext(initialState);

type ThemeProviderProps = {
  children: ReactNode;
};

function ThemeProvider({ children }: ThemeProviderProps) {
  const [config, setConfig] = useLocalStorage('bs-theme', {
    menuOrientation: initialState.menuOrientation,
    miniDrawer: initialState.miniDrawer,
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius,
    outlinedFilled: initialState.outlinedFilled,
    mode: initialState.mode,
    presetColor: initialState.presetColor,
    i18n: initialState.i18n,
    themeDirection: initialState.themeDirection,
    container: initialState.container,
  });

  const onChangeLocale = (i18n: I18n) => {
    setConfig({
      ...config,
      i18n,
    });
  };

  return (
    <ThemeContext.Provider
      value={{
        ...config,
        onChangeLocale,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, ThemeContext };
