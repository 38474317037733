import { IconButton, Stack } from '@mui/material';
import useAppContext from 'hooks/useAppContext';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Add } from '@mui/icons-material';
import { AppRoles } from 'entities/enums/AppRoles';
import { inRole } from 'utils/extensions';
import useMaps from 'hooks/useMaps';
import useNotification from 'hooks/useNotification';
import { Device } from 'entities';
import { SignalVM } from 'entities/viewModels';

type SignalDeviceTableCellActionProps = {
  item?: SignalVM;
  device?: Device;
};

export const SignalDeviceTableCellAction = ({ item }: SignalDeviceTableCellActionProps): ReactNode => {
  const navigate = useNavigate();
  const { contact } = useAppContext();
  const { warning } = useNotification();
  const { hasMapItem, moveToItem } = useMaps();

  if (!item?.device?.address && !item?.address) return <></>;

  // show add button for undefined device and signal contains name & address
  if (item?.device?.address === undefined && item?.address)
    return inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]) ? (
      <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
        <IconButton
          size='small'
          title='Add device'
          color='inherit'
          onClick={() => {
            navigate(`/devices/0`, { state: { name: item?.message ?? '', address: item.address } });
          }}
        >
          <Add />
        </IconButton>
      </Stack>
    ) : (
      <></>
    );

  return (
    <Stack direction='row' spacing={1} height={'100%'} alignItems='center'>
      <IconButton
        size='small'
        title='Show on map'
        color='inherit'
        onClick={() => {
          const address = item?.device?.address;
          if (!address) return;
          hasMapItem(address).then((item) => {
            if (item) {
              navigate('/maps');
              moveToItem(item);
            } else warning(`Device ${address} not found on map`);
          });
        }}
      >
        <LocationIcon />
      </IconButton>
    </Stack>
  );
};
