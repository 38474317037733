// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// project imports
import AppBar from 'components/extended/AppBar';
import HeaderSection from './HeaderSection';
import FooterSection from './FooterSection';

// types
import { ThemeMode } from 'types/theme';

const Landing = () => {
  const theme = useTheme();

  return (
    <>
      {/* 1. header and hero section */}
      <Box
        id='home'
        sx={{
          overflowX: 'hidden',
          overflowY: 'clip',
          background:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.background.default
              : `linear-gradient(360deg, ${theme.palette.grey[100]} 1.09%, ${theme.palette.background.paper} 100%)`,
        }}
      >
        <AppBar />
        <HeaderSection />
      </Box>
      {/* footer section */}
      <Box sx={{ py: 12.5, bgcolor: theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'dark.900', pb: 0 }}>
        <FooterSection />
      </Box>
    </>
  );
};

export default Landing;
