import { FormControl, FormLabel, FormHelperText, Button, TextField, Select, MenuItem, Divider, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { useState } from 'react';
import { Device, InspectionResult } from 'entities';
import { CameraAlt, Sensors } from '@mui/icons-material';
import { InspectionOutcome } from 'entities/enums/InspectionOutcome';
import { InspectionResultType } from 'views/Inspection';
import useInspection from 'hooks/useInspection';

type InspectionResultFormProps = {
  data?: { device?: Device; result: InspectionResult; type: InspectionResultType };
  editMode?: boolean;
  // onSubmit: (result: InspectionResult) => void;
};

export const InspectionResultForm = ({ data, editMode /*, onSubmit*/ }: InspectionResultFormProps) => {
  const { handleInspectionResultSubmit } = useInspection();
  const [outcome, setOutcome] = useState(editMode && data?.result ? (data?.type === 'Functional' ? data?.result?.outcomeFunctional : (data?.result.outcomeVisibility ?? 2)) : 2);
  const [comment, setComment] = useState(editMode && data?.result ? (data?.type === 'Functional' ? data.result?.commentFunctional : (data?.result.commentVisibility ?? '')) : '');

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('Please submit a comment');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (data?.result)
      data.type === 'Functional'
        ? handleInspectionResultSubmit({ ...data.result, outcomeFunctional: InspectionOutcome.FAIL, commentFunctional: comment })
        : handleInspectionResultSubmit({ ...data.result, outcomeVisibility: InspectionOutcome.FAIL, commentVisibility: comment });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl sx={{ m: 3, gap: '20px', width: '90%' }} variant='standard'>
        <Typography variant='h3'>{`${data?.type} check`}</Typography>
        <FormLabel sx={{ fontWeight: 700 }}>Device: {data?.device?.name}</FormLabel>
        <Divider />
        {/* <FormLabel id={'outcome-label'}>Outcome</FormLabel> */}
        <Select
          disabled={!editMode}
          //labelId="outcome-label"
          value={outcome}
          label='Outcome'
          onChange={(e) => setOutcome(parseInt(e.target.value.toString()))}
        >
          <MenuItem value={1}>{InspectionOutcome[0]}</MenuItem>
          <MenuItem value={2}>{InspectionOutcome[1]}</MenuItem>
        </Select>
        <TextField
          autoComplete='off'
          required={outcome === InspectionOutcome.FAIL}
          label='Comment'
          multiline
          rows={8}
          variant='standard'
          value={comment}
          error={error}
          onChange={(e) => setComment(e.target.value)}
        />
        <FormHelperText>{helperText}</FormHelperText>
        {/* {data?.type === 'Visibility' && <Dropzone />} */}
        <Button disabled={!comment} sx={{ mt: 1, mr: 1 }} type='submit' variant='contained'>
          Submit
        </Button>
        {data?.type === 'Functional' && (
          <>
            <Divider />
            <FormLabel sx={{ fontWeight: 700 }}>Signal(s) raised</FormLabel>
            <List dense={true}>
              {/* {data?.result?.signals?.map((s) => (
                <ListItem key={s.id}>
                  <ListItemIcon>
                    <Sensors />
                  </ListItemIcon>
                  <ListItemText primary={`${s.signalType?.name} ${s.address ? `[${s.address}]` : ''}`} secondary={new Date(s.date).toLocaleString()} />
                </ListItem>
              ))}  */}
              <ListItem>TODO ENRICH</ListItem>
            </List>
          </>
        )}
      </FormControl>
    </form>
  );
};
