import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid } from 'components/dataGrid/DataGrid';

export default function ShiftsTable() {
  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, '/shifts');
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Shift',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid dataUrl='/api/shift/me/query' hideHeader={false} orderBy={'date desc'} columnDefinitions={columns} />;
}
