import { Box, Button, Grid2 as Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import useAppContext from 'hooks/useAppContext';
import { useEffect, useState } from 'react';
import SignalsTable from 'collections/SignalsTable';
import EventItemsTable from 'collections/EventItemsTable';
import { AppRoles, SystemState } from 'entities/enums';
import useConfirm from 'hooks/useConfirm';
import useSignals from 'hooks/useSignals';
import { Toggler } from 'components/shared/Toggler';
import { SystemSelector } from 'components/shared/SystemSelector';
import InspectionsTable from 'collections/InspectionsTable';
import { inRole } from 'utils/extensions';
import { ClientTitle } from 'components/shared/ClientTitle';

const Dashboard = () => {
  const { context, contact } = useAppContext();
  const { confirm, ConfirmDialog } = useConfirm();
  const { states, resetSignalStates } = useSignals();

  const [show, setShow] = useState('Active');
  const [buildingId, setBuildingId] = useState<number>();

  const handleSignalReset = async () => {
    if (context?.systemState !== SystemState.Normal) return;
    if ((await confirm('Are you sure you want to reset all active states?')) && buildingId) await resetSignalStates(buildingId);
  };

  useEffect(() => {
    if (context?.buildingId) {
      setBuildingId(context?.buildingId);
    }
  }, [context]);

  return (
    <Grid spacing={gridSpacing} container>
      <Grid size={12}>
        {(contact?.systems?.length ?? 0) > 1 && (
          <MainCard title={'Beacon Suite Systems'}>
            <SystemSelector />
          </MainCard>
        )}
      </Grid>
      <Grid size={{ xs: 12, md: 8 }}>
        <MainCard
          title={<ClientTitle prefix={`${show} Signals for`} showSiteCode />}
          secondary={
            !inRole(contact?.role, [AppRoles.Viewer]) && (
              <Grid container>
                <Grid size={{ xs: 12, md: 12 }}>
                  {show === 'Active' && (
                    <Button
                      sx={{ mr: 1 }}
                      disabled={(context?.systemState ?? SystemState.Normal) !== SystemState.Normal || (states?.length ?? 0) === 0}
                      color='warning'
                      variant='contained'
                      onClick={handleSignalReset}
                    >
                      Reset
                    </Button>
                  )}
                  <Toggler id='dashboard-state' values={[{ name: 'Active' }, { name: 'All' }]} value={show} onChange={setShow} />
                </Grid>
              </Grid>
            )
          }
        >
          <ConfirmDialog />
          <SignalsTable showActions={true} activeOnly={show === 'Active'} />
        </MainCard>
      </Grid>
      <Grid size={{ xs: 12, md: 4 }}>
        <Box gap={1} display={'flex'} flexDirection={'column'}>
          <MainCard title={'Event Logs'}>
            <EventItemsTable pageSizeOptions={[5]} />
          </MainCard>
          {inRole(contact?.role, [AppRoles.Inspector]) && (
            <MainCard title={'Inspections'}>
              <InspectionsTable pageSizeOptions={[5]} limited={true} />
            </MainCard>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
