import React from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { SoloSelectFieldAttributes } from '.';

export interface SelectOption {
  label: string;
  value: any;
}

export const SoloSelectField: React.FC<SoloSelectFieldAttributes> = ({ label, name, data, disabled, control }) => {
  const { register, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      //rules={{ required: true }}
      disabled={disabled}
      render={({ field }) =>
        data && (
          <Autocomplete
            disablePortal
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField autoComplete='off' {...params} label={label} />}
            options={data}
            {...field}
            {...register(name)}
            size='small'
            style={{ width: '100%', margin: '5px' }}
            value={field.value}
            onChange={(e) => {}}
          />
        )
      }
    />
  );
};
