import { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Divider, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';
import useMaps from 'hooks/useMaps';
import useAppContext from 'hooks/useAppContext';

type MapSelectLayerProps = {
  width?: number;
};

export const MapSelectLayer = ({ width }: MapSelectLayerProps) => {
  const { context } = useAppContext();
  const { layers, setLayers } = useMaps();
  const [selected, setSelected] = useState<string[]>([]);

  const [items, setItems] = useState<{ key: string; label: string }[]>([
    { label: 'Map tiles', key: 'maps' },
    { label: 'Map items', key: 'mapItems' },
    { label: 'Devices layer', key: 'devices' },
    { label: `Grid layer`, key: 'grid' },
    { label: 'Rooms layer', key: 'rooms' },
    { label: 'Doors layer', key: 'doors' },
    { label: `Zones layer`, key: 'zones' },
  ]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const values = event.target.value;
    setSelected(typeof values === 'string' ? [values] : values);
    Object.keys(layers).forEach((key) => {
      layers[key] = values.includes(key);
    });
    setLayers(layers);
  };

  useEffect(() => {
    var keys = Object.keys(layers);
    setSelected(['maps']); //keys.filter((k) => layers[k]));
  }, [layers]);

  useEffect(() => {
    return () => {
      // reset default layers
      setLayers({ ...layers, maps: true, mapItems: true, roooms: true, grid: true });
    };
  }, []);

  return (
    <FormControl size='small' sx={width ? { width: width } : {}} fullWidth={!width} disabled={context?.maps?.length === 0}>
      <InputLabel id='layer-label'>Selected layers</InputLabel>
      <Select
        disabled
        multiple
        value={selected}
        labelId='layer-label'
        id='layer-select'
        label='Selected layers'
        onChange={handleChange}
        renderValue={(selected) =>
          items
            ?.filter((k) => selected.indexOf(k.key) !== -1)
            .map((k) => k.label)
            .join(', ')
        }
      >
        {items?.map((i) => (
          <MenuItem key={i.key} value={i.key}>
            <Checkbox checked={selected.includes(i.key)} />
            <ListItemText>{i.label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
